import { createSelector } from '@reduxjs/toolkit';
import { PRODUCTION_FISH_NAMES } from 'common/constants';

const mortalitySelector = (state) => state.mortality;

export const mortalityDetailSelector = createSelector(
    mortalitySelector,
    (mortality) => mortality.detail
);

export const mortalityProductionFishSelector = createSelector(
    mortalityDetailSelector,
    (mortalityDetail) =>
        mortalityDetail.data.fishes?.find((fish) =>
            PRODUCTION_FISH_NAMES.some((productionFish) => productionFish.id === fish.fishName)
        )
);

export const mortalityAttributeSelector = createSelector(
    mortalityProductionFishSelector,
    (mortalityProductionFish) =>
        mortalityProductionFish?.attributes.reduce((acc, obj) => {
            acc[obj.name] = obj.count;
            return acc;
        }, {})
);

export const mortalityAttachmentSelector = createSelector(
    mortalityProductionFishSelector,
    (mortalityProductionFish) =>
        mortalityProductionFish?.attributes.reduce((acc, obj) => {
            acc[obj.name] = obj.attachments;
            return acc;
        }, {})
);

export const mortalityDiagnosticAttributeSelector = createSelector(
    mortalityProductionFishSelector,
    (mortalityProductionFish) =>
        mortalityProductionFish?.diagnosticAttributes.reduce((acc, obj) => {
            acc[obj.name] = obj.value;
            return acc;
        }, {})
);
