import { appInsights } from 'AppInsights';
import { Button, Col, Input, Row } from 'antd';
import { BUTTON_AS_LINK, FISH_GROUP_DETAIL_PAGE, TABLE_COLUMNS } from 'common/constants';
import { blockInvalidChar, formatNumberToFixedDigits } from 'common/utils';
import { FDTable, ViewMoreAttachments, WoundAndSizeAttachment } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setSelectedFishGroupId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';

const DataTable = ({ reports, loading, isInvalidRecord, onChange }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { fishNames } = useContext(FiltersContext);

    const { t } = useTranslation();
    const { expandRowDailyReport } = useFlags();
    const [modalState, setModalState] = useState({ open: false, attachment: {} });

    const FISH_GROUPS_COLUMN = {
        title: 'general.table.fishGroups',
        dataIndex: 'fishGroups',
        className: 'table-report-fish-groups',
        render: (fishGroups) =>
            fishGroups.map((fishGroup) => (
                <button
                    className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                    key={fishGroup.id}
                    onClick={() => handleFishGroupClick(fishGroup)}
                >
                    {fishGroup.fishGroupId}-{fishGroup.sibling}
                </button>
            )),
        isDefault: true
    };

    const NOTE_COLUMN = {
        title: 'general.table.note',
        dataIndex: 'note',
        width: 170,
        isDefault: true,
        render: (_, record) => (
            <Input
                value={record.note}
                onChange={(e) => onChange(e, record.id, 'note')}
                data-testid={`note-${record.id}`}
            />
        )
    };

    const COLUMNS_FOR_DAILY_REPORT = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        FISH_GROUPS_COLUMN,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS,
        NOTE_COLUMN
    ];

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    // filter columns with api response
    const convertColumns = (columns) => {
        if (fishNames?.length) {
            columns = columns.filter((col) => fishNames.includes(col.dataIndex) || col.isDefault);
        }

        return columns;
    };

    // format the data to match the table
    const convertReportData = (reports) => {
        return reports.map((item, index) => {
            let obj = Object.assign(
                { key: index },
                item,
                item.fishes.reduce(
                    (accumulator, currentValue) => ({
                        ...accumulator,
                        [currentValue.fishId]: currentValue.dead,
                        [`${currentValue.fishId}_attachments`]: currentValue.attachments
                    }),
                    {}
                )
            );

            delete obj.fishes;

            return obj;
        });
    };

    const expandedRowRender = (record) => (
        <>
            <div className={`py-2 ml-16  ${isInvalidRecord(record) && 'invalid-row'}`}>
                <Row className="w-full flex items-center xs:gap-x-6 xl:gap-x-0">
                    <Col xl={6} xxl={4}>
                        <span className="font-medium underline">
                            {t('dailyReport.report.table.registerCauseOfDeath')}:
                        </span>
                    </Col>
                    <Col xl={4} xxl={3}>
                        <span className="mr-1">{t('general.table.uspess')}:</span>
                        <span>{record.uspess}</span>
                    </Col>
                    <Col xl={4} xxl={3}>
                        <span className="mr-1">{t('general.table.sar')}:</span>
                        <Input
                            min={0}
                            type="number"
                            value={record.sar}
                            onChange={(e) => onChange(e, record.id, 'sar')}
                            onKeyDown={blockInvalidChar}
                            className="w-20"
                            data-testid={`sar-${record.id}`}
                        />
                    </Col>
                    <Col xl={4} xxl={3}>
                        <span className="mr-1">{t('general.table.hsmb')}:</span>
                        <Input
                            min={0}
                            type="number"
                            value={record.hsmb}
                            onChange={(e) => onChange(e, record.id, 'hsmb')}
                            onKeyDown={blockInvalidChar}
                            className="w-20"
                            data-testid={`hsmb-${record.id}`}
                        />
                    </Col>
                    <Col xl={6} xxl={3}>
                        <span className="mr-1">{t('general.table.taperfisk')}:</span>
                        <Input
                            min={0}
                            type="number"
                            value={record.taperfisk}
                            onChange={(e) => onChange(e, record.id, 'taperfisk')}
                            onKeyDown={blockInvalidChar}
                            className="w-20"
                            data-testid={`taperfisk-${record.id}`}
                        />
                    </Col>
                </Row>
            </div>

            {expandRowDailyReport && (
                <>
                    <div className="py-2 ml-16">
                        <Row className="w-full flex items-start xs:gap-x-6 xl:gap-x-0">
                            <Col xl={2} xxl={1}>
                                <span className="font-medium underline">
                                    {t('dailyReport.report.table.overall')}:
                                </span>
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.numberOfAnalysis')}:
                                </span>
                                <span>{record.number_of_analyze}</span>
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.averageLength')}:
                                </span>
                                <span>
                                    {record.average_length != null &&
                                        `${formatNumberToFixedDigits(record.average_length)}cm`}
                                </span>
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.sizeOutlier')}:
                                </span>
                                <span>
                                    {record.size_outlier != null &&
                                        `${formatNumberToFixedDigits(record.size_outlier)}%`}
                                </span>
                            </Col>
                            <Col xl={6} xxl={4}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.woundPercentage')}:
                                </span>
                                <span>
                                    {record.wound_percentage != null &&
                                        `${formatNumberToFixedDigits(record.wound_percentage)}%`}
                                </span>
                            </Col>
                        </Row>
                    </div>
                    <div className="py-2 ml-16">
                        <Row className="w-full flex items-start xs:gap-x-6 xl:gap-x-0">
                            <Col xl={2} xxl={1}>
                                <span className="font-medium underline">
                                    {t('general.fishAttribute.wound')}:
                                </span>
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">{t('dailyReport.report.table.total')}:</span>
                                <span> {record.wound}</span>
                                <WoundAndSizeAttachment attachments={record.wound_attachments} />
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishAttribute.woundOnGill')}:
                                </span>
                                <span> {record.wound_on_gill}</span>
                                <WoundAndSizeAttachment
                                    attachments={record.wound_on_gill_attachments}
                                />
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishAttribute.woundOnMouth')}:
                                </span>
                                <span> {record.wound_on_mouth}</span>
                                <WoundAndSizeAttachment
                                    attachments={record.wound_on_mouth_attachments}
                                />
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishAttribute.woundOnBack')}:
                                </span>
                                <span>{record.wound_on_back}</span>
                                <WoundAndSizeAttachment
                                    attachments={record.wound_on_back_attachments}
                                />
                            </Col>
                            <Col xl={6} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishAttribute.woundOnBelly')}:
                                </span>
                                <span>{record.wound_on_belly}</span>
                                <WoundAndSizeAttachment
                                    attachments={record.wound_on_belly_attachments}
                                />
                            </Col>
                            <Col xs={0} xl={2} xxl={0}></Col> {/* Responsive purpose only */}
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishAttribute.woundOnTail')}:
                                </span>
                                <span>{record.wound_on_tail}</span>
                                <WoundAndSizeAttachment
                                    attachments={record.wound_on_tail_attachments}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="py-2 ml-16">
                        <Row className="w-full flex items-start xs:gap-x-6 xl:gap-x-0">
                            <Col xl={2} xxl={1}>
                                <span className="font-medium underline">
                                    {t('dailyReport.report.table.others')}:
                                </span>
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">{t('general.fishAttribute.fillet')}:</span>
                                <span> {record.fillet}</span>
                                <WoundAndSizeAttachment attachments={record.fillet_attachments} />
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">
                                    {t('general.fishAttribute.looserFish')}:
                                </span>
                                <span>{record.looser_fish}</span>
                                <WoundAndSizeAttachment
                                    attachments={record.looser_fish_attachments}
                                />
                            </Col>
                            <Col xl={4} xxl={3}>
                                <span className="mr-1">{t('general.fishAttribute.noWound')}:</span>
                                <span>{record.no_wound}</span>
                                <WoundAndSizeAttachment attachments={record.no_wound_attachments} />
                            </Col>
                        </Row>
                    </div>

                    <div className="flex justify-end">
                        <Button
                            onClick={() => {
                                setModalState({ open: true, attachment: record });
                            }}
                        >
                            {t('dailyReport.report.table.viewMoreImages')}
                        </Button>
                    </div>

                    <ViewMoreAttachments
                        open={modalState.open}
                        attachment={modalState.attachment}
                        onClose={() => setModalState({ open: false, attachment: {} })}
                    />
                </>
            )}
        </>
    );

    return (
        <FDTable
            headers={convertColumns(COLUMNS_FOR_DAILY_REPORT)}
            data={convertReportData(reports)}
            className="report-table"
            rowClassName="table-row"
            loading={loading}
            expandable={{ expandedRowRender }}
        />
    );
};

DataTable.propTypes = {
    reports: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    isInvalidRecord: PropTypes.func,
    onChange: PropTypes.func
};

export default DataTable;
