import { createSlice } from '@reduxjs/toolkit';
import {
    getStressAnalysisForCrowdingTimePerThrow,
    getStressAnalysisForEvent,
    getStressAnalysisForFish,
    getStressAnalysisForMortalityPerEvent,
    getStressAnalysisForOxygenLevelPerThrow,
    getStressAnalysisForStressLevelPerThrow,
    getStressAnalysisForStressZonePerThrow
} from 'redux/thunks';

const initialState = {
    event: { data: [], loading: false },
    fish: { data: [], loading: false },
    stressLevelPerThrow: { data: [], loading: false },
    crowdingTimePerThrow: { data: [], loading: false },
    stressZonePerThrow: { data: [], loading: false },
    oxygenLevelPerThrow: { data: [], loading: false },
    mortalityPerEvent: { data: [], loading: false }
};

export const stressAnalysisSlice = createSlice({
    name: 'stressAnalysis',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getStressAnalysisForEvent.pending, (state) => {
            state.event.loading = true;
        });
        builder.addCase(getStressAnalysisForEvent.fulfilled, (state, action) => {
            state.event.loading = false;
            state.event.data = action.payload;
        });
        builder.addCase(getStressAnalysisForEvent.rejected, (state) => {
            state.event.loading = false;
            state.event.data = initialState.event.data;
        });

        builder.addCase(getStressAnalysisForFish.pending, (state) => {
            state.fish.loading = true;
        });
        builder.addCase(getStressAnalysisForFish.fulfilled, (state, action) => {
            state.fish.loading = false;
            state.fish.data = action.payload;
        });
        builder.addCase(getStressAnalysisForFish.rejected, (state) => {
            state.fish.loading = false;
            state.fish.data = initialState.fish.data;
        });

        builder.addCase(getStressAnalysisForStressLevelPerThrow.pending, (state) => {
            state.stressLevelPerThrow.loading = true;
        });
        builder.addCase(getStressAnalysisForStressLevelPerThrow.fulfilled, (state, action) => {
            state.stressLevelPerThrow.loading = false;
            state.stressLevelPerThrow.data = action.payload;
        });
        builder.addCase(getStressAnalysisForStressLevelPerThrow.rejected, (state) => {
            state.stressLevelPerThrow.loading = false;
            state.stressLevelPerThrow.data = initialState.stressLevelPerThrow.data;
        });

        builder.addCase(getStressAnalysisForCrowdingTimePerThrow.pending, (state) => {
            state.crowdingTimePerThrow.loading = true;
        });
        builder.addCase(getStressAnalysisForCrowdingTimePerThrow.fulfilled, (state, action) => {
            state.crowdingTimePerThrow.loading = false;
            state.crowdingTimePerThrow.data = action.payload;
        });
        builder.addCase(getStressAnalysisForCrowdingTimePerThrow.rejected, (state) => {
            state.crowdingTimePerThrow.loading = false;
            state.crowdingTimePerThrow.data = initialState.crowdingTimePerThrow.data;
        });

        builder.addCase(getStressAnalysisForStressZonePerThrow.pending, (state) => {
            state.stressZonePerThrow.loading = true;
        });
        builder.addCase(getStressAnalysisForStressZonePerThrow.fulfilled, (state, action) => {
            state.stressZonePerThrow.loading = false;
            state.stressZonePerThrow.data = action.payload;
        });
        builder.addCase(getStressAnalysisForStressZonePerThrow.rejected, (state) => {
            state.stressZonePerThrow.loading = false;
            state.stressZonePerThrow.data = initialState.stressZonePerThrow.data;
        });

        builder.addCase(getStressAnalysisForOxygenLevelPerThrow.pending, (state) => {
            state.oxygenLevelPerThrow.loading = true;
        });
        builder.addCase(getStressAnalysisForOxygenLevelPerThrow.fulfilled, (state, action) => {
            state.oxygenLevelPerThrow.loading = false;
            state.oxygenLevelPerThrow.data = action.payload;
        });
        builder.addCase(getStressAnalysisForOxygenLevelPerThrow.rejected, (state) => {
            state.oxygenLevelPerThrow.loading = false;
            state.oxygenLevelPerThrow.data = initialState.oxygenLevelPerThrow.data;
        });

        builder.addCase(getStressAnalysisForMortalityPerEvent.pending, (state) => {
            state.mortalityPerEvent.loading = true;
        });
        builder.addCase(getStressAnalysisForMortalityPerEvent.fulfilled, (state, action) => {
            state.mortalityPerEvent.loading = false;
            state.mortalityPerEvent.data = action.payload;
        });
        builder.addCase(getStressAnalysisForMortalityPerEvent.rejected, (state) => {
            state.mortalityPerEvent.loading = false;
            state.mortalityPerEvent.data = initialState.mortalityPerEvent.data;
        });
    }
});
