import { createSlice } from '@reduxjs/toolkit';
import { getServices } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getServices.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getServices.fulfilled, (state, action) => {
            state.loading = false;
            state.data =
                action.payload.filter(
                    (service) => service.isUsed && service.status === 'Released'
                ) || initialState.data;
        });
        builder.addCase(getServices.rejected, (state) => {
            state.loading = false;
            state.data = initialState.data;
        });
    }
});
