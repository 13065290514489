import { createSlice } from '@reduxjs/toolkit';
import { getCompanyNotifications } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const companyNotificationSlice = createSlice({
    name: 'companyNotification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getCompanyNotifications.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
