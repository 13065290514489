import { appInsights } from 'AppInsights';
import { Button, Card, Spin } from 'antd';
import {
    AGGREGATE_GRAPH,
    BAR_CHART,
    BUTTON_AS_LINK,
    BUTTON_BLUE_SELECTED,
    BUTTON_BLUE_UN_SELECTED,
    BUTTON_GRAY_SELECTED,
    BUTTON_GRAY_UN_SELECTED,
    FISH_GROUP_DETAIL_PAGE,
    LINE_CHART,
    MORTALITY_ANALYSIS_PAGE,
    PER_DAY_GRAPH
} from 'common/constants';
import { COMPANY_CONFIG, MORTALITY_CONFIG } from 'common/constants/company-config';
import { formatDateTime } from 'common/utils';
import { DualMortalityPercentagesChart, FDColumn } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    setChartType,
    setGraphType,
    setSelectedFishGroupId,
    setSelectedPens,
    setSelectedSites
} from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getCompanyConfigByName, getDeadFishMortality, getFishGroupMortality } from 'redux/thunks';

const FDFishMortalityChart = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { siteId, penId } = useParams();

    const { graphType, timePeriod, chartType } = useSelector((s) => s.filter.data);
    const { data: mortalityData, loading: chartLoading } = useSelector((s) => s.deadFishMortality);
    const fishGroupMortalityData = useSelector((s) => s.fishGroupMortality.data);
    const { data: companyConfigDetail, loading: companyConfigLoading } = useSelector(
        (s) => s.companyConfigDetail
    );

    const formattedMortalityData = mortalityData.map((item) => ({
        type: t(`general.fishMortality.${item.name}`),
        value: item.amount,
        name: formatDateTime(item.day),
        percentages: item.percentages
    }));

    const formattedMortalityLevelUnwantedConfig = companyConfigDetail?.value?.filter(
        (mortalityLevelConfig) => mortalityLevelConfig.key === MORTALITY_CONFIG.UNWANTED.key
    )[0];

    const formattedMortalityLevelUnacceptableConfig = companyConfigDetail?.value?.filter(
        (mortalityLevelConfig) => mortalityLevelConfig.key === MORTALITY_CONFIG.UNACCEPTABLE.key
    )[0];

    const mortalityLevelChartConfig = [
        {
            type: 'line',
            start: ['min', formattedMortalityLevelUnwantedConfig?.from],
            end: ['max', formattedMortalityLevelUnwantedConfig?.from],
            style: {
                stroke: formattedMortalityLevelUnwantedConfig?.color,
                width: 4,
                lineDash: [4, 4]
            }
        },
        {
            type: 'line',
            start: ['min', formattedMortalityLevelUnacceptableConfig?.from],
            end: ['max', formattedMortalityLevelUnacceptableConfig?.from],
            style: {
                stroke: formattedMortalityLevelUnacceptableConfig?.color,
                width: 4,
                lineDash: [4, 4]
            }
        }
    ];

    const formattedFishGroupMortalityData = fishGroupMortalityData.flatMap((item) => [
        {
            type: 'text',
            position: [formatDateTime(item.day), 'max'],
            content: item.name,
            style: {
                textBaseline: 'bottom'
            }
        },
        {
            type: 'line',
            start: [formatDateTime(item.day), '0'],
            end: [formatDateTime(item.day), 'max']
        }
    ]);

    const chartLineConfig = {
        annotations: {
            value: penId && formattedFishGroupMortalityData,
            percentages: graphType === AGGREGATE_GRAPH ? [] : mortalityLevelChartConfig
        }
    };

    const chartBarConfig = {
        annotations: penId && formattedFishGroupMortalityData
    };

    useEffect(() => {
        dispatch(getCompanyConfigByName({ name: COMPANY_CONFIG.MORTALITY_LEVEL }));
    }, []);

    useEffect(() => {
        dispatch(
            getDeadFishMortality({
                siteIds: [siteId],
                penIds: [penId],
                fromDate: timePeriod.fromDate,
                graphType: graphType
            })
        );

        if (penId) {
            dispatch(
                getFishGroupMortality({
                    penIds: [penId],
                    fromDate: timePeriod.fromDate
                })
            );
        }
    }, [siteId, penId, timePeriod.fromDate, graphType]);

    // handle graph type change
    const handleGraphTypeChange = (graphType) => {
        dispatch(setGraphType(graphType));

        appInsights.trackEvent({
            name: 'Fish mortality graph type change',
            properties: { graphType: graphType }
        });
    };

    // handle chart type change
    const handleChartTypeChange = (chartType) => {
        dispatch(setChartType(chartType));

        appInsights.trackEvent({
            name: 'Fish mortality chart type change',
            properties: { chartType: chartType }
        });
    };

    const navigateToMortalityPage = async () => {
        appInsights.trackEvent({
            name: 'Fish mortality chart click navigate to mortality page'
        });

        dispatch(setSelectedSites(siteId ? [siteId] : []));
        dispatch(setSelectedPens(penId ? [penId] : []));

        navigate(MORTALITY_ANALYSIS_PAGE);
    };

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    return (
        <Spin spinning={chartLoading || companyConfigLoading}>
            <Card bordered className="mt-2">
                <div className="flex xs:flex-col lg:flex-row pb-5">
                    <div className="flex-1"></div>

                    <div className="xs:mt-4 lg:mt-0 flex-1 text-center">
                        <Button
                            className={
                                chartType === LINE_CHART
                                    ? BUTTON_GRAY_SELECTED
                                    : BUTTON_GRAY_UN_SELECTED
                            }
                            onClick={() => handleChartTypeChange(LINE_CHART)}
                        >
                            {t('dashboard.fishMortality.mortalityChart.line')}
                        </Button>
                        &nbsp;
                        <Button
                            className={
                                chartType === BAR_CHART
                                    ? BUTTON_GRAY_SELECTED
                                    : BUTTON_GRAY_UN_SELECTED
                            }
                            onClick={() => handleChartTypeChange(BAR_CHART)}
                        >
                            {t('dashboard.fishMortality.mortalityChart.bar')}
                        </Button>
                    </div>

                    <div className="xs:mt-4 lg:mt-0 flex-1 text-center lg:text-end">
                        <Button
                            className={
                                graphType === AGGREGATE_GRAPH
                                    ? BUTTON_BLUE_SELECTED
                                    : BUTTON_BLUE_UN_SELECTED
                            }
                            onClick={() => handleGraphTypeChange(AGGREGATE_GRAPH)}
                        >
                            {t('dashboard.fishMortality.mortalityChart.aggregate')}
                        </Button>
                        &nbsp;
                        <Button
                            className={
                                graphType === PER_DAY_GRAPH
                                    ? BUTTON_BLUE_SELECTED
                                    : BUTTON_BLUE_UN_SELECTED
                            }
                            onClick={() => handleGraphTypeChange(PER_DAY_GRAPH)}
                        >
                            {t('dashboard.fishMortality.mortalityChart.perDay')}
                        </Button>
                    </div>
                </div>

                <div className="min-h-[400px]">
                    {chartType === LINE_CHART ? (
                        <DualMortalityPercentagesChart
                            data={formattedMortalityData}
                            chartConfig={chartLineConfig}
                        />
                    ) : (
                        <FDColumn data={formattedMortalityData} chartConfig={chartBarConfig} />
                    )}
                </div>

                <div className="flex xs:flex-col lg:flex-row mt-4">
                    <div className="flex-1">
                        {penId && fishGroupMortalityData.length > 0 && (
                            <div className="mb-4 lg:mb-0 text-center lg:text-start">
                                <span className="mr-4 font-semibold">
                                    {t('dashboard.fishMortality.mortalityChart.listOfFishGroups')}
                                </span>
                                {fishGroupMortalityData.map((fishGroup) => (
                                    <button
                                        className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                                        key={fishGroup.id}
                                        onClick={() => handleFishGroupClick(fishGroup)}
                                    >
                                        {fishGroup.name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="text-center lg:text-end">
                        <button
                            id="navigate-to-mortality-page"
                            className={BUTTON_AS_LINK}
                            onClick={() => navigateToMortalityPage()}
                        >
                            {t('component.fishMortality.navigateToMortalityPage')}
                        </button>
                    </div>
                </div>
            </Card>
        </Spin>
    );
};

export default FDFishMortalityChart;
