import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import {
    getDailyEvent,
    getDailyMortality,
    getFishwellFishIdsSelection,
    getPensSelection,
    getSitesSelection
} from 'redux/thunks';
import EventTable from './EventTable';
import ReportTable from './ReportTable';
import { FiltersContext } from 'context/filtersContext';

const DailyReport = () => {
    const dispatch = useAppDispatch();

    const { stressEventSwitch } = useFlags();

    const [filters, setFilters] = useState({});

    useEffect(() => {
        dispatch(getPensSelection());
        dispatch(getSitesSelection());
        dispatch(getFishwellFishIdsSelection());
    }, []);

    const handleFilterSubmit = useCallback((query) => {
        setFilters(query);

        if (stressEventSwitch) dispatch(getDailyEvent(query));

        dispatch(getDailyMortality(query));

        appInsights.trackEvent({ name: 'Daily filter apply' });
    }, []);

    return (
        <div className="daily-report">
            <FDFilter
                hasSite
                hasPen
                hasFishName
                hasFishGroupIds
                hasDate
                callback={handleFilterSubmit}
            />

            <FiltersContext.Provider value={filters}>
                <ReportTable />
            </FiltersContext.Provider>

            {stressEventSwitch && <EventTable />}
        </div>
    );
};

export default withPageViewTracking(DailyReport, 'Daily report');
