import { TABLE_COLUMNS } from 'common/constants';
import { FDTable } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const EventTable = () => {
    const { t } = useTranslation();

    const { data: events, loading } = useSelector((s) => s.dailyEvent);

    const COLUMNS_FOR_DAILY_EVENT = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        TABLE_COLUMNS.BOAT,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        TABLE_COLUMNS.EVENT_TYPE
    ];

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('dailyReport.events.title')}</h2>
            <FDTable headers={COLUMNS_FOR_DAILY_EVENT} data={events} loading={loading} />
        </div>
    );
};

export default EventTable;
