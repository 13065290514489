import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const useQueryFromFilters = ({
    hasComparison,
    hasSite,
    hasPen,
    hasFishGroupIds,
    hasServiceBoat,
    hasDateRange,
    hasDate,
    hasFishName
}) => {
    const query = {};

    const filters = useSelector((s) => s.filter.data);

    const addQueryParam = (condition, key, value) => {
        if (condition) {
            query[key] = value;
        }
    };

    addQueryParam(hasComparison, 'siteIds', filters.comparison.siteIds);
    addQueryParam(hasComparison, 'penIds', filters.comparison.penIds);

    addQueryParam(hasSite, 'siteIds', filters.siteIds);
    addQueryParam(hasPen, 'penIds', filters.penIds);
    addQueryParam(hasFishGroupIds, 'fishGroupIds', filters.fishGroupIds);
    addQueryParam(hasServiceBoat, 'serviceBoatIds', filters.serviceBoatIds);
    addQueryParam(hasFishName, 'fishNames', filters.fishNames);

    addQueryParam(hasDateRange, 'fromDate', filters.fromDate);
    addQueryParam(hasDateRange, 'toDate', filters.toDate);

    addQueryParam(hasDate, 'fromDate', filters.daily.fromDate);
    addQueryParam(hasDate, 'toDate', filters.daily.toDate);

    return query;
};

useQueryFromFilters.propTypes = {
    hasComparison: PropTypes.bool,
    hasSite: PropTypes.bool,
    hasPen: PropTypes.bool,
    hasFishGroupIds: PropTypes.bool,
    hasServiceBoat: PropTypes.bool,
    hasDateRange: PropTypes.bool,
    hasDate: PropTypes.bool,
    hasTimePeriod: PropTypes.bool,
    hasDailyDate: PropTypes.bool,
    hasFishName: PropTypes.bool
};
