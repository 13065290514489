import { createSlice } from '@reduxjs/toolkit';
import { addPen, deletePen, getPens, restorePen, updatePen } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const penSlice = createSlice({
    name: 'pen',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPens.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPens.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getPens.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(addPen.pending, (state) => {
                state.loading = true;
            })
            .addCase(addPen.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(addPen.rejected, (state) => {
                state.loading = false;
            })

            .addCase(updatePen.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePen.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updatePen.rejected, (state) => {
                state.loading = false;
            })

            .addCase(deletePen.pending, (state) => {
                state.loading = true;
            })
            .addCase(deletePen.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deletePen.rejected, (state) => {
                state.loading = false;
            })

            .addCase(restorePen.pending, (state) => {
                state.loading = true;
            })
            .addCase(restorePen.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(restorePen.rejected, (state) => {
                state.loading = false;
            });
    }
});
