import { Select } from 'antd';
import PropTypes from 'prop-types';

const FDMultipleSelection = ({
    onChange,
    className,
    placeholder,
    defaultValue,
    dataTestId,
    value,
    listSelectItem = [],
    loading = false,
    showSearch = true
}) => {
    const { Option } = Select;

    function handleChange(value) {
        if (onChange) onChange(value);
    }

    return (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={placeholder || 'Please select'}
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleChange}
            defaultValue={defaultValue}
            className={className}
            showArrow
            showSearch={showSearch}
            value={value}
            loading={loading}
        >
            {listSelectItem.map((item) => (
                <Option value={item.id} key={item.id} data-testid={dataTestId}>
                    {item.text}
                </Option>
            ))}
        </Select>
    );
};

FDMultipleSelection.propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    dataTestId: PropTypes.string,
    value: PropTypes.array,
    listSelectItem: PropTypes.array,
    loading: PropTypes.bool,
    showSearch: PropTypes.bool
};

export default FDMultipleSelection;
