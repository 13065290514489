import { createSlice } from '@reduxjs/toolkit';
import { getFishwellFishIdsSelection } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const fishwellFishIdSelectionSlice = createSlice({
    name: 'fishwellFishIdSelection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFishwellFishIdsSelection.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFishwellFishIdsSelection.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getFishwellFishIdsSelection.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
