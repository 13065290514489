import { createSlice } from '@reduxjs/toolkit';
import { getFishHealthByFishGroupId } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const fishGroupFishHealthSlice = createSlice({
    name: 'fishGroupFishHealth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFishHealthByFishGroupId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFishHealthByFishGroupId.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getFishHealthByFishGroupId.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
