import { appInsights } from 'AppInsights';
import { Card, Col, Row } from 'antd';
import {
    BUTTON_AS_LINK,
    DATE_TIME_FORMAT,
    FISH_GROUP_DETAIL_PAGE,
    ORANGE,
    RED,
    SILVER
} from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDPercentage } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    mortalityAttributeSelector,
    mortalityDetailSelector,
    mortalityDiagnosticAttributeSelector
} from 'redux/selector';
import { useAppDispatch } from 'redux/store';
import { updateFilters } from 'redux/thunks';

const MortalityInfo = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const { data: mortality } = useSelector(mortalityDetailSelector);
    const attribute = useSelector(mortalityAttributeSelector);
    const diagnosticAttribute = useSelector(mortalityDiagnosticAttributeSelector);

    const infoList = [
        {
            key: t('mortality.location'),
            value:
                mortality.siteName &&
                mortality.penNumber &&
                `${mortality.siteName} - ${mortality.penNumber}`,
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('mortality.fishGroup'),
            value: mortality.fishGroups?.map((fishGroup) => (
                <button
                    className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                    key={fishGroup.id}
                    onClick={() => handleFishGroupClick(fishGroup)}
                >
                    {fishGroup.fishGroupId}-{fishGroup.sibling}
                </button>
            )),
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('mortality.time'),
            value: (
                <>
                    <span>{formatDateTime(mortality.startTime, DATE_TIME_FORMAT)}</span>
                    {' - '}
                    <span className="inline-block">
                        {formatDateTime(mortality.stopTime, DATE_TIME_FORMAT)}
                    </span>
                </>
            ),
            col: { xs: 24, sm: 12, xl: 12, xxl: 10 }
        },
        {
            key: t('general.fishDiagnosticAttribute.numberOfAnalysis'),
            value: diagnosticAttribute?.number_of_analyze,
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('general.fishDiagnosticAttribute.averageLength'),
            value: diagnosticAttribute?.average_length,
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('mortality.fishCount'),
            value: mortality.fishes
                ?.map((fish) => `${t(`general.fishMortality.${fish.fishName}`)}: ${fish.count}`)
                .join(', '),
            col: { xs: 12, xl: 12, xxl: 10 }
        },
        {
            key: t('general.fishAttribute.looserFish'),
            value: attribute?.looser_fish,
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('general.fishAttribute.fillet'),
            value: attribute?.fillet,
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('general.fishAttribute.wound'),
            value:
                attribute?.wound &&
                attribute?.no_wound &&
                `${t('general.fishAttribute.wound')}: ${attribute.wound}, ${t(
                    'general.fishAttribute.noWound'
                )}: ${attribute.no_wound}`,
            col: { xs: 12, xl: 12, xxl: 10 }
        }
    ];

    const handleFishGroupClick = (fishGroup) => {
        dispatch(
            updateFilters({
                selectedFishGroupId: fishGroup.id
            })
        );

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    return (
        <Card className="event-info mt-2">
            <Row className="text-base" gutter={8}>
                <Col xs={24} xl={16} xxl={17}>
                    <Row>
                        {infoList.map((info) => (
                            <Col key={info.key} {...info.col} className="flex flex-col mb-5">
                                <span className="font-semibold">{info.key}</span>
                                <span>{info.value}</span>
                            </Col>
                        ))}
                    </Row>
                </Col>

                <Col xs={24} xl={8} xxl={7}>
                    <Row className="items-center h-full">
                        <Col xs={12}>
                            <span className="font-semibold">
                                {t('general.fishDiagnosticAttribute.woundPercentage')}
                            </span>
                            <FDPercentage
                                height={110}
                                width={110}
                                value={diagnosticAttribute?.wound_percentage}
                                color={[RED, SILVER]}
                            />
                        </Col>

                        <Col xs={12}>
                            <span className="font-semibold">
                                {t('general.fishDiagnosticAttribute.sizeOutlier')}
                            </span>
                            <FDPercentage
                                height={110}
                                width={110}
                                value={diagnosticAttribute?.size_outlier}
                                color={[ORANGE, SILVER]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default MortalityInfo;
