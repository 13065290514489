import { createSlice } from '@reduxjs/toolkit';
import { getDailyMortality, updateDailyMortality } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const dailyMortalitySlice = createSlice({
    name: 'dailyMortality',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDailyMortality.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDailyMortality.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getDailyMortality.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(updateDailyMortality.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateDailyMortality.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateDailyMortality.rejected, (state) => {
                state.loading = false;
            });
    }
});
