import { createSlice } from '@reduxjs/toolkit';
import { addSite, deleteSite, getSites, restoreSite, updateSite } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSites.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSites.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getSites.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(addSite.pending, (state) => {
                state.loading = true;
            })
            .addCase(addSite.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(addSite.rejected, (state) => {
                state.loading = false;
            })

            .addCase(updateSite.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSite.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateSite.rejected, (state) => {
                state.loading = false;
            })

            .addCase(deleteSite.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSite.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteSite.rejected, (state) => {
                state.loading = false;
            })

            .addCase(restoreSite.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreSite.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(restoreSite.rejected, (state) => {
                state.loading = false;
            });
    }
});
