import { createSlice } from '@reduxjs/toolkit';
import { FISH_NAMES } from 'common/constants';
import { getDeadFishCountingMortality } from 'redux/thunks';

const initialState = {
    data: {
        counts: [
            ...FISH_NAMES.map((fish) => ({ fishName: fish.id, totalAmount: 0 })),
            { fishName: 'total', totalAmount: 0 }
        ],
        charts: []
    },
    loading: false
};

export const deadFishCountingMortalitySlice = createSlice({
    name: 'deadFishCountingMortality',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDeadFishCountingMortality.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDeadFishCountingMortality.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getDeadFishCountingMortality.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
