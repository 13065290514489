import { createSlice } from '@reduxjs/toolkit';
import { getDeadFishDailyCounting } from 'redux/thunks';

const initialState = {
    data: 0,
    loading: false
};

export const dailyReportCountingSlice = createSlice({
    name: 'dailyReportCounting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDeadFishDailyCounting.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDeadFishDailyCounting.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload?.length
                    ? action.payload[0].currentCount + action.payload[0].cleansingFishCurrentCount
                    : initialState.data;
            })
            .addCase(getDeadFishDailyCounting.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
