import { createSlice } from '@reduxjs/toolkit';
import { getEventListByFishGroupId } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const fishGroupEventListSlice = createSlice({
    name: 'fishGroupEventList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEventListByFishGroupId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEventListByFishGroupId.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getEventListByFishGroupId.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
