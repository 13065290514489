import { createSlice } from '@reduxjs/toolkit';
import {
    getMortalityAnalysisWoundTypeDeadFishCountReport,
    getMortalityAnalysisWoundTypeStockCountReport
} from 'redux/thunks';

const initialState = {
    woundTypeWithDeadFishCount: [],
    woundTypeWithStockCount: [],
    loading: false
};

export const mortalityAnalysisWoundTypeReportSlice = createSlice({
    name: 'mortalityAnalysisWoundTypeReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMortalityAnalysisWoundTypeDeadFishCountReport.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                getMortalityAnalysisWoundTypeDeadFishCountReport.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.woundTypeWithDeadFishCount = action.payload || initialState.data;
                }
            )
            .addCase(getMortalityAnalysisWoundTypeDeadFishCountReport.rejected, (state) => {
                state.loading = false;
                state.woundTypeWithDeadFishCount = initialState.data;
            })

            .addCase(getMortalityAnalysisWoundTypeStockCountReport.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMortalityAnalysisWoundTypeStockCountReport.fulfilled, (state, action) => {
                state.loading = false;
                state.woundTypeWithStockCount = action.payload || initialState.data;
            })
            .addCase(getMortalityAnalysisWoundTypeStockCountReport.rejected, (state) => {
                state.loading = false;
                state.woundTypeWithStockCount = initialState.data;
            });
    }
});
