import { Button, Card, Col, Row, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDMultipleSelection, FDRangeDate } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getMortalityDetailList,
    getPensSelection,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';

const FilterMortalities = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: mortalities, loading } = useSelector((s) => s.mortality.detailList);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const allFishwellFishIds = useSelector((s) => s.fishwellFishIdSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [fishGroupsSelection, setFishGroupsSelection] = useState([]);
    const [filters, setFilters] = useState({
        siteIds: initialFilters.siteIds,
        penIds: initialFilters.penIds,
        fishGroupIds: initialFilters.fishGroupIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
        dispatch(getFishwellFishIdsSelection());
    }, []);

    useEffect(() => {
        dispatch(
            getMortalityDetailList({
                siteIds: initialFilters.siteIds,
                penIds: initialFilters.penIds,
                fishGroupIds: initialFilters.fishGroupIds,
                fromDate: initialFilters.fromDate,
                toDate: initialFilters.toDate
            })
        );
    }, [
        initialFilters.siteIds,
        initialFilters.penIds,
        initialFilters.fishGroupIds,
        initialFilters.fromDate,
        initialFilters.toDate
    ]);

    useEffect(() => {
        if (mortalities.some((mortality) => mortality.id === initialFilters.selectedMortalityId)) {
            return;
        }

        handleMortalityChange(mortalities[0]?.id || null);
    }, [mortalities]);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.siteIds.length)
            pensSelection = allPens.filter((pen) => initialFilters.siteIds.includes(pen.siteId));

        setPensSelection(pensSelection);
    }, [allPens]);

    useEffect(() => {
        setFishGroupsSelection(allFishwellFishIds);
    }, [allFishwellFishIds]);

    const handleSitesChange = (siteIds) => {
        let selectedPens = filters.penIds;
        let newPensSelections = allPens;

        let selectedFishGroups = filters.fishGroupIds;
        let newFishGroupsSelections = allFishwellFishIds;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) => siteIds.includes(pen.siteId));

            selectedPens = filters.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );

            newFishGroupsSelections = allFishwellFishIds.filter((fishGroup) =>
                siteIds.includes(fishGroup.siteId)
            );

            selectedFishGroups = filters.fishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
        }

        setPensSelection(newPensSelections);
        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            siteIds: siteIds,
            penIds: selectedPens,
            fishGroupIds: selectedFishGroups
        });
    };

    const handlePensChange = (penIds) => {
        let selectedFishGroups = filters.fishGroupIds;
        let newFishGroupsSelections = allFishwellFishIds;

        if (penIds.length) {
            newFishGroupsSelections = allFishwellFishIds.filter((fishGroup) =>
                penIds.includes(fishGroup.penId)
            );

            selectedFishGroups = filters.fishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
        }

        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            penIds: penIds,
            fishGroupIds: selectedFishGroups
        });
    };

    const handleFishGroupsChange = (fishGroupIds) => {
        setFilters({
            ...filters,
            fishGroupIds: fishGroupIds
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterSubmit = () => {
        dispatch(
            updateFilters({
                siteIds: filters.siteIds,
                penIds: filters.penIds,
                fishGroupIds: filters.fishGroupIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate
            })
        );

        appInsights.trackEvent({ name: 'Mortality filter apply' });
    };

    const handleMortalityChange = (mortalityId) => {
        dispatch(
            updateFilters({
                selectedMortalityId: mortalityId
            })
        );

        appInsights.trackEvent({ name: 'Selected mortality change', properties: { mortalityId } });
    };

    return (
        <div className="filter-bar flex flex-col">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>

            <div className="filter md:text-base rounded-lg flex flex-wrap">
                <div className="filter-bar grow">
                    <Row>
                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.site')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.sitesPlaceholder')}
                                listSelectItem={sitesSelection}
                                onChange={handleSitesChange}
                                value={filters.siteIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.pen')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.pensPlaceholder')}
                                listSelectItem={pensSelection}
                                onChange={handlePensChange}
                                value={filters.penIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.fishGroups')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.fishGroupsPlaceholder')}
                                listSelectItem={fishGroupsSelection.map((fishGroup) => ({
                                    ...fishGroup,
                                    text: `${fishGroup.text} - ${fishGroup.penNumber} - ${fishGroup.siteName}`
                                }))}
                                onChange={handleFishGroupsChange}
                                value={filters.fishGroupIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                            <FDRangeDate
                                placeholder={[
                                    t('general.filterBar.startDatePlaceholder'),
                                    t('general.filterBar.endDatePlaceholder')
                                ]}
                                onChange={handleRangeDateChange}
                                value={[filters.fromDate, filters.toDate]}
                            />
                        </Col>

                        <Col>
                            <Button
                                loading={loading}
                                className={BUTTON_BLUE_CLASS}
                                onClick={handleFilterSubmit}
                            >
                                {t('general.filterBar.apply')}
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className="min-w-[200px] pt-2.5 pr-2.5 pl-2.5 mb-2.5 grow xl:grow-0">
                    <div className="font-semibold">{t('mortality.fishMortalities')}</div>
                    <Card className="h-[110px]">
                        <Spin spinning={loading}>
                            {!(mortalities.length || loading) ? (
                                <p className="m-0 text-center text-red-600">
                                    {t('mortality.noFishMortalitiesFound')}
                                </p>
                            ) : (
                                <div className="max-h-[90px] overflow-y-auto overflow-x-hidden">
                                    <table className="whitespace-nowrap">
                                        <tbody>
                                            {mortalities.map((mortality) => (
                                                <tr
                                                    key={mortality.id}
                                                    className={`cursor-pointer hover:font-medium transition px-2 
                                                            ${
                                                                mortality.id ===
                                                                    initialFilters.selectedMortalityId &&
                                                                'bg-sky-700 text-white font-medium'
                                                            }`}
                                                    onClick={() =>
                                                        handleMortalityChange(mortality.id)
                                                    }
                                                >
                                                    <td className="px-2">
                                                        {formatDateTime(mortality.stopTime)}
                                                    </td>
                                                    <td className="px-2">{mortality.site}</td>
                                                    <td className="px-2">{mortality.pen}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Spin>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default FilterMortalities;
