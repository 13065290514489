import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_AS_LINK,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    FISH_GROUP_DETAIL_PAGE,
    MORTALITY_PAGE,
    TABLE_COLUMNS
} from 'common/constants';
import { FDTable } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedFishGroupId, setSelectedMortalityId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import MortalityForm from '../MortalityForm';

const TableMortalityList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: mortalities, loading } = useSelector((s) => s.mortality.list);
    const [fishMortalityFormModalState, setFishMortalityFormModalState] = useState({
        open: false,
        fishMortality: null
    });

    const handleFishMortalityFormShowModal = (record) => {
        setFishMortalityFormModalState({ open: true, fishMortality: record });

        appInsights.trackEvent({
            name: 'Fish mortality edit button click',
            properties: { fishMortalityId: record.id }
        });
    };

    const handleMortalityClick = async (mortalityId) => {
        dispatch(setSelectedMortalityId(mortalityId));

        navigate(MORTALITY_PAGE);

        appInsights.trackEvent({
            name: 'Mortality detail button click',
            properties: { mortalityId }
        });
    };

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    // format the data to match the table
    const convertReportData = (reports) => {
        return reports?.map((item, index) => {
            let obj = Object.assign(
                { key: index },
                item,
                item.fishes.reduce(
                    (accumulator, currentValue) => ({
                        ...accumulator,
                        [currentValue.fishName]: currentValue.count
                    }),
                    {}
                )
            );

            return obj;
        });
    };

    const COLUMNS_FOR_EVENT_LIST = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        {
            title: 'general.table.fishGroups',
            dataIndex: 'fishGroups',
            render: (fishGroups) =>
                fishGroups.map((fishGroup) => (
                    <button
                        className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                        key={fishGroup.id}
                        onClick={() => handleFishGroupClick(fishGroup)}
                    >
                        {fishGroup.fishGroupId}-{fishGroup.sibling}
                    </button>
                )),
            sorter: {
                compare: (a, b) => a.fishGroups.length - b.fishGroups.length,
                multiple: 5
            }
        },
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        {
            title: 'general.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_GRAY_CLASS}
                        onClick={() => handleFishMortalityFormShowModal(record)}
                    >
                        <EditOutlined />
                        <span>{t('general.action.edit')}</span>
                    </Button>
                    <Button
                        className={BUTTON_BLUE_CLASS}
                        onClick={() => {
                            handleMortalityClick(record.id);
                        }}
                    >
                        <EyeOutlined />
                        <span>{t('mortalityList.detail')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('mortalityList.title')}</h2>
            <FDTable
                headers={COLUMNS_FOR_EVENT_LIST}
                data={convertReportData(mortalities)}
                loading={loading}
            />
            <MortalityForm
                open={fishMortalityFormModalState.open}
                fishMortality={fishMortalityFormModalState.fishMortality}
                onClose={() => setFishMortalityFormModalState({ open: false, fishMortality: null })}
            />
        </div>
    );
};

export default TableMortalityList;
