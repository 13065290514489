import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import {
    getFishwellFishIdsSelection,
    getPensSelection,
    getServiceBoatsSelection,
    getSitesSelection
} from 'redux/thunks';

const initialState = {
    fishGroupIds: { data: [], selection: [], loading: false },
    pens: { data: [], selection: [], comparisonSelection: [], loading: false },
    sites: { data: [], loading: false },
    serviceBoats: { data: [], loading: false },

    fishNames: { data: [], loading: false }
};

export const selectionSlice = createSlice({
    name: 'selection',
    initialState,
    reducers: {
        setFishGroupIdsSelection(state, action) {
            state.fishGroupIds.selection = action.payload;
        },
        setPensSelection(state, action) {
            state.pens.selection = action.payload;
        },
        setComparisonPensSelection(state, action) {
            state.pens.comparisonSelection = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFishwellFishIdsSelection.pending, (state) => {
                state.fishGroupIds.loading = true;
            })
            .addCase(getFishwellFishIdsSelection.fulfilled, (state, action) => {
                if (!isEqual(state.fishGroupIds, action.payload))
                    state.fishGroupIds.data = action.payload || [];

                state.fishGroupIds.loading = false;
            })
            .addCase(getFishwellFishIdsSelection.rejected, (state) => {
                state.fishGroupIds = initialState.fishGroupIds;
            })

            .addCase(getPensSelection.pending, (state) => {
                state.pens.loading = true;
            })
            .addCase(getPensSelection.fulfilled, (state, action) => {
                if (!isEqual(state.pens.data, action.payload))
                    state.pens.data = action.payload || [];

                state.pens.loading = false;
            })
            .addCase(getPensSelection.rejected, (state) => {
                state.pens = initialState.pens;
            })

            .addCase(getSitesSelection.pending, (state) => {
                state.sites.loading = true;
            })
            .addCase(getSitesSelection.fulfilled, (state, action) => {
                if (!isEqual(state.sites.data, action.payload))
                    state.sites.data = action.payload || [];

                state.sites.loading = false;
            })
            .addCase(getSitesSelection.rejected, (state) => {
                state.sites = initialState.sites;
            })

            .addCase(getServiceBoatsSelection.pending, (state) => {
                state.serviceBoats.loading = true;
            })
            .addCase(getServiceBoatsSelection.fulfilled, (state, action) => {
                state.serviceBoats.data = action.payload || [];
                state.serviceBoats.loading = false;
            })
            .addCase(getServiceBoatsSelection.rejected, (state) => {
                state.serviceBoats = initialState.serviceBoats;
            });
    }
});

export const { setFishGroupIdsSelection, setPensSelection, setComparisonPensSelection } =
    selectionSlice.actions;
