import axios from 'axios';
import { BASE_GLOBAL_API_URL, TIME_ZONE } from 'common/constants';
import qs from 'qs';
import { getToken } from './Auth';
import { cancelDuplicatedRequest, removeCompletedRequest } from './utils';
import { appInsights } from 'AppInsights';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        accept: 'application/json',
        contentType: 'application/json',
        'Time-Zone': TIME_ZONE,
        Authorization: ''
    }
};

const get = (url, params = {}, options = {}) => {
    return instance.get(BASE_GLOBAL_API_URL + url, {
        ...defaultOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const interceptorHandleRequest = async (config) => {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;

    cancelDuplicatedRequest(config);

    return config;
};
const interceptorHandleResponse = (response) => {
    removeCompletedRequest(response.config);
    return response;
};

const handleError = (error) => {
    if (axios.isCancel(error)) {
        return;
    }

    appInsights.trackException({ exception: error });

    return Promise.reject(error.response.data.errors || error.response.data.message);
};

instance.interceptors.request.use(interceptorHandleRequest, handleError);
instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { get };
