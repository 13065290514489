import {
    ApartmentOutlined,
    AppstoreOutlined,
    AreaChartOutlined,
    BarChartOutlined,
    CalculatorOutlined,
    DotChartOutlined,
    DribbbleOutlined,
    ExperimentOutlined,
    FileDoneOutlined,
    FileTextOutlined,
    FundProjectionScreenOutlined,
    GroupOutlined,
    HomeOutlined,
    MailOutlined,
    RetweetOutlined,
    TagOutlined,
    UngroupOutlined,
    UsergroupAddOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import { useIsAuthenticated } from '@azure/msal-react';
import { Layout as LibLayout, Menu } from 'antd';
import useAppAbility from 'casl/can';
import {
    ADMINISTRATOR,
    COMPANY_CONFIG_PAGE,
    COMPARISON_PAGE,
    DAILY_REPORT_PAGE,
    DASHBOARD_PAGE,
    FISH_GROUP_DETAIL_PAGE,
    FISH_GROUP_PAGE,
    FLOW_PAGE,
    GUEST_USER_INVITATION_PAGE,
    MORTALITY_ANALYSIS_PAGE,
    MORTALITY_LIST_PAGE,
    MORTALITY_PAGE,
    OTHER_DEAD_FISH_COUNT_PAGE,
    PEN_PAGE,
    PROCESS_PAGE,
    SCORING_EVENT_PAGE,
    SITE_MANAGEMENT_PAGE,
    SITE_MANAGER,
    STRESS_ANALYSIS_PAGE,
    STRESS_EVENT_PAGE,
    STRESS_LIST_PAGE,
    USER,
    USER_PAGE,
} from 'common/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import i18n from 'translation/i18n';
import './Sidebar.scss';

const Sidebar = () => {
    const { t } = useTranslation();
    const { Sider } = LibLayout;
    const { can } = useAppAbility();
    const [collapsed] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const location = useLocation();

    const { stressEventSwitch, showStressAnalysis, showFlow } = useFlags();

    const farmerMenu = useMemo(() => {
        if (!isAuthenticated) return [];
        return [
            {
                icon: <HomeOutlined />,
                label: <Link to={DASHBOARD_PAGE}>{t('layout.sideBar.dashboard')}</Link>,
                key: DASHBOARD_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <RetweetOutlined />,
                label: <Link to={COMPARISON_PAGE}>{t('layout.sideBar.comparison')}</Link>,
                key: COMPARISON_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <FileDoneOutlined />,
                label: <Link to={DAILY_REPORT_PAGE}>{t('layout.sideBar.dailyReport')}</Link>,
                key: DAILY_REPORT_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <AreaChartOutlined />,
                label: t('layout.sideBar.mortality'),
                key: 'Mortality',
                visible: can('manage', USER, ['all']),
                children: [
                    {
                        icon: <UnorderedListOutlined />,
                        label: <Link to={MORTALITY_LIST_PAGE}>{t('layout.sideBar.list')}</Link>,
                        key: MORTALITY_LIST_PAGE,
                        visible: can('manage', USER, ['all'])
                    },
                    {
                        icon: <FileTextOutlined />,
                        label: <Link to={MORTALITY_PAGE}>{t('layout.sideBar.detail')}</Link>,
                        key: MORTALITY_PAGE,
                        visible: can('manage', USER, ['all'])
                    },
                    {
                        icon: <DotChartOutlined />,
                        label: (
                            <Link to={MORTALITY_ANALYSIS_PAGE}>{t('layout.sideBar.analysis')}</Link>
                        ),
                        key: MORTALITY_ANALYSIS_PAGE,
                        visible: can('manage', USER, ['all'])
                    }
                ]
            },
            {
                icon: <FileDoneOutlined />,
                label: <Link to={STRESS_LIST_PAGE}>{t('layout.sideBar.stressEvent')}</Link>,
                key: 'Stress event',
                visible: (stressEventSwitch || showStressAnalysis) && can('manage', USER, ['all']),
                children: [
                    {
                        icon: <UnorderedListOutlined />,
                        label: <Link to={STRESS_LIST_PAGE}>{t('layout.sideBar.list')}</Link>,
                        key: STRESS_LIST_PAGE,
                        visible: stressEventSwitch && can('manage', USER, ['all'])
                    },
                    {
                        icon: <FileTextOutlined />,
                        label: <Link to={STRESS_EVENT_PAGE}>{t('layout.sideBar.detail')}</Link>,
                        key: STRESS_EVENT_PAGE,
                        visible: stressEventSwitch && can('manage', USER, ['all'])
                    },
                    {
                        icon: <DotChartOutlined />,
                        label: (
                            <Link to={STRESS_ANALYSIS_PAGE}>{t('layout.sideBar.analysis')}</Link>
                        ),
                        key: STRESS_ANALYSIS_PAGE,
                        visible: showStressAnalysis && can('manage', USER, ['all'])
                    }
                ]
            },
            {
                icon: <BarChartOutlined />,
                label: <Link to={FLOW_PAGE}>{t('layout.sideBar.flow')}</Link>,
                key: FLOW_PAGE,
                visible: showFlow && can('manage', USER, ['all'])
            },
            {
                icon: <TagOutlined />,
                label: <Link to={SCORING_EVENT_PAGE}>{t('layout.sideBar.scoringEvent')}</Link>,
                key: SCORING_EVENT_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <UngroupOutlined />,
                label: <Link to={PROCESS_PAGE}>{t('layout.sideBar.process')}</Link>,
                key: PROCESS_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <FundProjectionScreenOutlined />,
                label: (
                    <Link to={FISH_GROUP_DETAIL_PAGE}>{t('layout.sideBar.fishGroupDetail')}</Link>
                ),
                key: FISH_GROUP_DETAIL_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <AppstoreOutlined />,
                key: 'Management',
                label: t('layout.sideBar.management'),
                visible: can('manage', SITE_MANAGER, ['all']),
                children: [
                    {
                        icon: <ExperimentOutlined />,
                        label: (
                            <Link to={COMPANY_CONFIG_PAGE}>
                                {t('layout.sideBar.companyConfig')}
                            </Link>
                        ),
                        key: COMPANY_CONFIG_PAGE,
                        visible: can('manage', ADMINISTRATOR, ['all'])
                    },
                    {
                        icon: <UsergroupAddOutlined />,
                        label: <Link to={USER_PAGE}>{t('layout.sideBar.user')}</Link>,
                        key: USER_PAGE,
                        visible: can('manage', ADMINISTRATOR, ['all'])
                    },
                    {
                        icon: <MailOutlined />,
                        label: (
                            <Link to={GUEST_USER_INVITATION_PAGE}>
                                {t('layout.sideBar.guestUserInvitation')}
                            </Link>
                        ),
                        key: GUEST_USER_INVITATION_PAGE,
                        visible: can('manage', ADMINISTRATOR, ['all'])
                    },
                    {
                        icon: <GroupOutlined />,
                        label: <Link to={SITE_MANAGEMENT_PAGE}>{t('layout.sideBar.site')}</Link>,
                        key: SITE_MANAGEMENT_PAGE,
                        visible: can('manage', ADMINISTRATOR, ['all'])
                    },
                    {
                        icon: <DribbbleOutlined />,
                        label: <Link to={PEN_PAGE}>{t('layout.sideBar.pen')}</Link>,
                        key: PEN_PAGE,
                        visible: can('manage', SITE_MANAGER, ['all'])
                    },
                    {
                        icon: <ApartmentOutlined />,
                        label: <Link to={FISH_GROUP_PAGE}>{t('layout.sideBar.fishGroup')}</Link>,
                        key: FISH_GROUP_PAGE,
                        visible: can('manage', SITE_MANAGER, ['all'])
                    },
                    {
                        icon: <CalculatorOutlined />,
                        label: (
                            <Link to={OTHER_DEAD_FISH_COUNT_PAGE}>
                                {t('layout.sideBar.otherDeadFishCount')}
                            </Link>
                        ),
                        key: OTHER_DEAD_FISH_COUNT_PAGE,
                        visible: can('manage', SITE_MANAGER, ['all'])
                    }
                ]
            }
        ]
            .filter((ability) => ability.visible)
            .map(({ visible, ...item }) => ({
                ...item,
                children: item.children
                    ?.filter((ability) => ability.visible)
                    .map(({ visible, ...item }) => item)
            }));
    }, [isAuthenticated, i18n.language, stressEventSwitch, showStressAnalysis, showFlow]);

    return (
        <Sider trigger={null} className="sidebar" collapsible collapsed={collapsed} width="250">
            <Link to="/">
                <div className={`brand-name ${collapsed && 'collapsed'}`}>
                    <div className="logo" />
                </div>
            </Link>
            <Menu
                theme="light"
                className="menu"
                selectedKeys={[location.pathname]}
                defaultSelectedKeys={['/']}
                mode="inline"
                items={farmerMenu}
            />
        </Sider>
    );
};

export default Sidebar;
