import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en';
import translationNB from './locales/nb';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    nb: {
        translation: translationNB
    }
};

i18n.use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'nb', // default language
        fallbackLng: 'en', // fallback language in case the specified language file is not found
        debug: process.env.NODE_ENV !== 'test',
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
