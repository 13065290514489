import { Button, Form, Modal, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS, BUTTON_WHITE_CLASS, SUCCESSFULLY_UPDATE_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { updateStressEvent } from 'redux/thunks';

const StressEventForm = ({ open = false, stressEvent, onClose = () => {}}) => {
    const [form] = Form.useForm();
    const selectedSiteId = Form.useWatch('siteId', form);
    const selectedPenId = Form.useWatch('penId', form);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const loading = useSelector((s) => s.eventList.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const pensSelection = useSelector((s) => s.penSelection.data);

    useEffect(() => {
        form.setFieldsValue(stressEvent);
    }, [stressEvent]);

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    const handleSiteChange = (siteId, site) => {
        if (!site?.pens?.some((pen) => pen.id === selectedPenId)) {
            form.setFieldValue('penId', null);
        }
    };

    const handleUpdateStressEvent = async (stressEventUp) => {
        try {
            await dispatch(updateStressEvent({id: stressEvent?.id, item: stressEventUp})).unwrap();

            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Stress event update',
            properties: { stressEventId: stressEvent?.id }
        });
    }

    return (
        <Modal
            title={t('stressEventList.stressEventForm.updateStressEvent')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS} disabled={selectedPenId === stressEvent?.penId}>
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleUpdateStressEvent}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label={t('general.form.site.title')} name="siteId">
                        <FDSelection
                            placeholder={t('general.form.site.placeholder')}
                            listSelectItem={sitesSelection}
                            onChange={handleSiteChange}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.pen.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('general.form.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) => (!selectedSiteId || pen.siteId === selectedSiteId)
                            )}
                            onChange={(penId, pen) => form.setFieldValue('siteId', pen?.siteId)}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}

StressEventForm.propTypes = {
    open: PropTypes.bool,
    stressEvent: PropTypes.object,
    onClose: PropTypes.func,
};

export default StressEventForm;
