import { createSlice } from '@reduxjs/toolkit';
import { getCurrentProfile, updateCurrentProfile } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCurrentProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCurrentProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        });
        builder.addCase(getCurrentProfile.rejected, (state) => {
            state.loading = false;
            state.data = initialState.data;
        });

        builder.addCase(updateCurrentProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateCurrentProfile.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(updateCurrentProfile.rejected, (state) => {
            state.loading = false;
        });
    }
});
