import { DATE_TIME_FORMAT } from 'common/constants';
import { formatDateTime, getRandomBoolean } from 'common/utils';
import { FDFishMortality, FDGeneralInfo, FDItemList } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getDeadFishDailyCounting, getPenDetail, getStockCount } from 'redux/thunks';
import './index.scss';

const PenDetail = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { penId } = useParams();

    const { data: pen, loading } = useSelector((s) => s.penDetail);

    const viewedPens = JSON.parse(sessionStorage.getItem('viewedPens')) || [];

    useEffect(() => {
        if (!viewedPens.includes(penId)) {
            viewedPens.push(penId);
            sessionStorage.setItem('viewedPens', JSON.stringify(viewedPens));
        }

        dispatch(getPenDetail(penId));

        dispatch(getDeadFishDailyCounting({ penIds: [penId] }));

        dispatch(
            getStockCount({
                penIds: [penId]
            })
        );
    }, [penId]);

    const infoList = [
        {
            key: t('penDetail.farmerCompany'),
            value: pen.company?.name
        },
        {
            key: t('penDetail.site'),
            value: pen.site?.siteName
        },
        {
            key: t('penDetail.localityNumber'),
            value: pen.site?.localityNumber
        },
        {
            key: t('penDetail.penNumber'),
            value: pen.penNumber
        },
        {
            key: t('penDetail.typeOfFish'),
            value: pen.fishGroups
                ?.map((fishGroup) => t(`general.fishMortality.${fishGroup.typeOfFish}`))
                .join(', ')
        },
        {
            key: t('penDetail.totalFishCount'),
            value: pen.fishGroups
                ?.map(
                    (fishGroup) =>
                        `${fishGroup.totalFishCount} - ${formatDateTime(
                            fishGroup.actionDate,
                            DATE_TIME_FORMAT
                        )}`
                )
                .join(', ')
        }
    ];

    return (
        <div className="pen-detail">
            <div>
                <h2 className="mb-2">{t('penDetail.title')}</h2>
                <FDGeneralInfo
                    penId={penId}
                    loading={loading}
                    logo={pen.company?.logoUrl}
                    infoList={infoList}
                    status={pen.status}
                />
            </div>

            <FDFishMortality />

            <div className="mt-5">
                <FDItemList
                    loading={loading}
                    title={t('penDetail.relatedPens')}
                    itemList={pen.relatedPens?.map((item) => ({
                        ...item,
                        name: item.penNumber,
                        link: `../pen-detail/${item.id}`,
                        hasViewed: viewedPens.includes(item.id),
                        isCurrentItem: item.id === penId,
                        hasNotification: getRandomBoolean()
                    }))}
                />
            </div>
        </div>
    );
};

export default withPageViewTracking(PenDetail, 'Pen detail');
