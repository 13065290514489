import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    STRESS_EVENT_PAGE,
    TABLE_COLUMNS
} from 'common/constants';
import { FDTable } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedStressEventId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import StressEventForm from '../StressEventForm';

const TableEventList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: events, loading } = useSelector((s) => s.eventList);
    const [stressEventFormModalState, setStressEventFormModalState] = useState({
        open: false,
        stressEvent: null
    });

    const handleEventClick = async (eventId) => {
        dispatch(setSelectedStressEventId(eventId));

        navigate(STRESS_EVENT_PAGE, { state: { navigatedEventId: eventId } });

        appInsights.trackEvent({
            name: 'Stress event detail button click',
            properties: { eventId }
        });
    };

    const handleStressEventFormShowModal = (record) => {
        setStressEventFormModalState({ open: true, stressEvent: record });

        appInsights.trackEvent({
            name: 'Stress event edit button click',
            properties: { eventId: record.id }
        });
    };

    const COLUMNS_FOR_EVENT_LIST = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        TABLE_COLUMNS.BOAT,
        TABLE_COLUMNS.BOAT_COMPANY,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        {
            title: 'general.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_GRAY_CLASS}
                        onClick={() => handleStressEventFormShowModal(record)}
                    >
                        <EditOutlined />
                        <span>{t('general.action.edit')}</span>
                    </Button>
                    <Button
                        className={BUTTON_BLUE_CLASS}
                        onClick={() => {
                            handleEventClick(record.id);
                        }}
                    >
                        <EyeOutlined />
                        <span>{t('stressEventList.detail')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('stressEventList.events')}</h2>
            <FDTable headers={COLUMNS_FOR_EVENT_LIST} data={events} loading={loading} />
            <StressEventForm
                open={stressEventFormModalState.open}
                stressEvent={stressEventFormModalState.stressEvent}
                onClose={() => setStressEventFormModalState({ open: false, stressEvent: null })}
            />
        </div>
    );
};

export default TableEventList;
