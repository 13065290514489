import { Button, Form, InputNumber, Modal, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    SUCCESSFULLY_UPDATE_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { updateFishMortality } from 'redux/thunks';

const MortalityForm = ({ open = false, fishMortality, onClose = () => {} }) => {
    const [form] = Form.useForm();
    const selectedSiteId = Form.useWatch('siteId', form);
    const selectedPenId = Form.useWatch('penId', form);
    const [fishes, setFishes] = useState([]);
    const [isDirty, setIsDirty] = useState(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const loading = useSelector((s) => s.mortality.list.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const pensSelection = useSelector((s) => s.penSelection.data);

    useEffect(() => {
        form.setFieldsValue(fishMortality);
        setFishes(fishMortality?.fishes || []);
    }, [fishMortality]);

    useEffect(() => {
        const dirty = detectIsDirty();
        setIsDirty(dirty);
    }, [form.getFieldsValue()]);

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    const detectIsDirty = () => {
        const isFishCountChanged = fishes.some((fish) => {
            const oldDeadFishCount = fishMortality?.fishes.find(
                (f) => f.deadFishDetectionId === fish.deadFishDetectionId
            )?.count;
            return fish.count !== oldDeadFishCount;
        });

        return (
            isFishCountChanged ||
            selectedSiteId !== fishMortality?.siteId ||
            selectedPenId !== fishMortality?.penId
        );
    };

    const handleSiteChange = (siteId, site) => {
        if (!site?.pens?.some((pen) => pen.id === selectedPenId)) {
            form.setFieldValue('penId', null);
        }
    };

    const handleFishCountChange = (fish, count) => {
        form.setFieldValue(fish.fishName, count);
        setFishes(
            fishes.map((f) => {
                if (f.deadFishDetectionId === fish.deadFishDetectionId) {
                    return { ...f, count };
                }
                return f;
            })
        );
    };

    const handleUpdateFishMortality = async (fishMortalityUp) => {
        const fishMortalityUpRequest = {
            deadFishCounts: fishes?.map((count) => ({
                deadFishDetectionId: count.deadFishDetectionId,
                count: count.count
            })),
            siteId: fishMortalityUp.siteId,
            penId: fishMortalityUp.penId
        };
        try {
            await dispatch(updateFishMortality(fishMortalityUpRequest)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Fish mortality update',
            properties: { fishMortalityId: fishMortality?.id }
        });
    };

    return (
        <Modal
            title={t('mortalityList.mortalityForm.updateMortality')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS} disabled={!isDirty}>
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleUpdateFishMortality}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label={t('general.form.site.title')} name="siteId">
                        <FDSelection
                            placeholder={t('general.form.site.placeholder')}
                            listSelectItem={sitesSelection}
                            onChange={handleSiteChange}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.pen.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('general.form.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) => !selectedSiteId || pen.siteId === selectedSiteId
                            )}
                            onChange={(penId, pen) => form.setFieldValue('siteId', pen?.siteId)}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    {fishes?.map((fish) => (
                        <Form.Item
                            key={fish.deadFishDetectionId}
                            label={t(`general.fishMortality.${fish.fishName}`)}
                            name={fish.fishName}
                            rules={[
                                {
                                    required: true,
                                    message: t('mortalityList.mortalityForm.count.required')
                                },
                                {
                                    type: 'number',
                                    message: t('mortalityList.mortalityForm.count.invalid')
                                }
                            ]}
                        >
                            <InputNumber
                                min={0}
                                max={999999}
                                style={{ width: 60 }}
                                value={fish.count}
                                onChange={(number) => handleFishCountChange(fish, number)}
                            />
                        </Form.Item>
                    ))}
                </Form>
            </Spin>
        </Modal>
    );
};

MortalityForm.propTypes = {
    open: PropTypes.bool,
    fishMortality: PropTypes.object,
    onClose: PropTypes.func
};

export default MortalityForm;
