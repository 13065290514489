import { appInsights } from 'AppInsights';
import { Button } from 'antd';
import {
    BLUE,
    BUTTON_GRAY_CLASS,
    DAILY_REPORT_NAME,
    EXPORT_ALL,
    EXPORT_BY_FILTER,
    EXPORT_DAILY_REPORT_API,
    LIGHT_GRAY
} from 'common/constants';
import { FDDropdownDownload } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getDailyMortality, updateDailyMortality } from 'redux/thunks';
import Swal from 'sweetalert2';
import ConfirmDialog from './ConfirmDialog';
import DataTable from './DataTable';
import './index.scss';

const ReportTable = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const filters = useContext(FiltersContext);

    const { data: dailyReports, loading } = useSelector((s) => s.dailyMortality);

    const [reports, setReports] = useState([]);
    const [changedArr, setChangedArr] = useState([]);

    const cloneReports = dailyReports?.map((item, index) => ({
        id: index,
        ...item
    }));

    const downloadOptions = [
        {
            label: t('dailyReport.report.download.byCurrentFilter'),
            params: { ...filters, exportType: EXPORT_BY_FILTER }
        },
        {
            label: t('dailyReport.report.download.allData'),
            params: { ...filters, exportType: EXPORT_ALL }
        }
    ];

    useEffect(() => {
        setChangedArr([]);

        setReports(
            dailyReports?.map((item, index) => ({
                id: index,
                ...item
            }))
        );
    }, [dailyReports]);

    // event change classify cell
    const onChange = (e, index, key) => {
        let newReports = reports.map((reportObj) => ({ ...reportObj }));

        let obj = newReports[index];
        if (key === 'note') {
            obj[key] = e.target.value;
        } else {
            obj.uspess = obj.uspess + (obj[key] || 0) - parseInt(e.target.value || 0);
            obj[key] = parseInt(e.target.value);
        }

        setReports(newReports);

        // update value for array of changed index
        const cloneChangedArr = [...changedArr];
        if (isChanged(index, cloneReports, newReports)) {
            if (!cloneChangedArr.includes(index)) {
                cloneChangedArr.push(index);
            }
        } else {
            const i = cloneChangedArr.indexOf(index);
            if (i > -1) {
                cloneChangedArr.splice(i, 1);
            }
        }

        setChangedArr(cloneChangedArr);
    };

    // check if there is a difference between previous data and current data
    const isChanged = (index, initArr, newArr) => {
        return (
            !isEqual(initArr[index]?.sar, newArr[index]?.sar) ||
            !isEqual(initArr[index]?.hsmb, newArr[index]?.hsmb) ||
            !isEqual(initArr[index]?.taperfisk, newArr[index]?.taperfisk) ||
            !isEqual(initArr[index]?.note, newArr[index]?.note)
        );
    };

    // compare two values
    const isEqual = (value1, value2) => {
        return (!value1 && !value2) || value1 === value2;
    };

    // check data has invalid value
    const isInvalidRecord = (record) => {
        return record.uspess < 0 || record.sar < 0 || record.hsmb < 0 || record.taperfisk < 0;
    };

    // save changes to database
    const onSaveChanges = () => {
        Swal.fire({
            title: t('general.action.saveModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.saveModal.confirmText'),
            cancelButtonText: t('general.action.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                const changedItems = changedArr.map((index) => ({
                    deadFishDetectionId: reports[index].deadFishDetectionId,
                    uspess: reports[index].uspess,
                    sar: reports[index].sar || 0,
                    hsmb: reports[index].hsmb || 0,
                    taperfisk: reports[index].taperfisk || 0,
                    note: reports[index].note || ''
                }));

                const causeOfFishDeath = { deadFishCauseOfDeathEditList: changedItems };

                await dispatch(updateDailyMortality(causeOfFishDeath)).unwrap();

                dispatch(getDailyMortality(filters));
                setChangedArr([]);
            }
        });

        appInsights.trackEvent({ name: 'Daily report - Register cause of death updated' });
    };

    return (
        <div className="mt-5 daily-report-table">
            <div className="flex justify-between w-full mb-2">
                <h2 className="mb-0">{t('dailyReport.report.title')}</h2>
                <div className="flex justify-end items-end">
                    <Button
                        className={`${BUTTON_GRAY_CLASS}`}
                        disabled={
                            !changedArr.length ||
                            changedArr.some((index) => isInvalidRecord(reports[index]) === true)
                        }
                        onClick={onSaveChanges}
                    >
                        {t('general.form.saveChange')}
                    </Button>
                    &nbsp;
                    <FDDropdownDownload
                        options={downloadOptions}
                        exportApi={EXPORT_DAILY_REPORT_API}
                        fileName={DAILY_REPORT_NAME}
                    />
                </div>
            </div>

            <DataTable
                reports={reports}
                isInvalidRecord={isInvalidRecord}
                loading={loading}
                onChange={onChange}
            />

            <ConfirmDialog show={changedArr.length} />
        </div>
    );
};

export default ReportTable;
