import { createAsyncThunk } from '@reduxjs/toolkit';
import { PROCESS_API, PUBLIC_PROCESS_API } from 'common/constants';
import moment from 'moment';
import { API, GLOBAL_API } from 'services';

export const getProcessList = createAsyncThunk(
    'processes/getProcessList',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(`${PROCESS_API}/list`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getProcessStressEvent = createAsyncThunk(
    'processes/getProcessStressEvent',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${PROCESS_API}/${id}/stress-events`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getProcessFlowDetection = createAsyncThunk(
    'processes/getProcessFlowDetection',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${PROCESS_API}/${id}/flow-detections`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getProcessWelfareScore = createAsyncThunk(
    'processes/getProcessWelfareScore',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${PROCESS_API}/${id}/welfare-scores`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getWaterTemperature = createAsyncThunk(
    'processes/getWaterTemperature',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await GLOBAL_API.get(`${PUBLIC_PROCESS_API}/${id}/site-water-temperature`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
