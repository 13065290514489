import { Button, Modal } from 'antd';
import { BUTTON_WHITE_CLASS, DATE_TIME_FORMAT } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDTable } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HistoryTable = ({ open, data, onClose = () => {} }) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataIndex: 'actionDate',
            title: 'fishGroup.table.actionDate',
            render: (actionDate) => formatDateTime(actionDate, DATE_TIME_FORMAT)
        },
        {
            dataIndex: 'status',
            title: 'fishGroup.historyTable.status'
        },
        {
            dataIndex: 'fishGroupId',
            title: 'fishGroup.table.fishwellFishId',
            render: (fishGroupId, record) => `${fishGroupId}-${record.sibling}`
        },
        {
            dataIndex: 'siteName',
            title: 'general.table.siteName'
        },
        {
            dataIndex: 'penNumber',
            title: 'general.table.penNumber'
        },
        {
            dataIndex: 'totalFishCount',
            title: 'fishGroup.table.totalFishCount'
        }
    ];

    return (
        <Modal
            title={t('fishGroup.historyTable.title')}
            width={800}
            open={open}
            onCancel={onClose}
            footer={
                <Button type="default" onClick={onClose} className={BUTTON_WHITE_CLASS}>
                    {t('general.action.close')}
                </Button>
            }
        >
            <FDTable headers={columns} data={data} rowKey="id" />
        </Modal>
    );
};

HistoryTable.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.array,
    onClose: PropTypes.func
};

export default HistoryTable;
