import {
    CaretRightOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    EyeOutlined,
    InfoCircleFilled
} from '@ant-design/icons';
import { Button, Spin } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_LIGHT_GREEN_CLASS,
    GRAY,
    LIGHT_GREEN,
    LIVE_STRESS_DETECTION_PAGE,
    ORANGE
} from 'common/constants';
import propTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setStressDetectionStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getStressEngineStatus } from 'redux/thunks';

const ServiceBoatStatus = ({ serviceBoat = {}, onPenListOpen = () => {} }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (serviceBoat.hasEngineBaseUrl) {
            dispatch(
                getStressEngineStatus({
                    baseUrl: serviceBoat.engineBaseUrl,
                    serviceBoatId: serviceBoat.id
                })
            );
        }
    }, [serviceBoat.engineBaseUrl]);

    // const handlerStartButtonClick = () => {
    //     onPenListOpen(serviceBoat);
    // };

    const handleViewButtonClick = () => {
        dispatch(
            setStressDetectionStreaming({
                isDetecting: true,
                engineBaseUrl: serviceBoat.engineBaseUrl,
                serviceBoatId: serviceBoat.id,
                serviceBoatName: serviceBoat.name,
                penNumber: serviceBoat.penNumber
            })
        );
        navigate(LIVE_STRESS_DETECTION_PAGE);
    };

    const getServiceBoatStyle = () => {
        if (serviceBoat.isDetecting) {
            return {
                borderColor: 'border-blue-500',
                icon: <Spin />,
                status: `${t('liveStressDetection.isDetecting')} ${serviceBoat?.penNumber ?? ''}`,
                button: (
                    <Button
                        className={BUTTON_BLUE_CLASS}
                        icon={<EyeOutlined />}
                        onClick={() => handleViewButtonClick()}
                    >
                        {t('button.view')}
                    </Button>
                )
            };
        }

        if (serviceBoat.hasEngineBaseUrl) {
            return {
                borderColor: 'border-green-600',
                icon: <CheckCircleFilled style={{ fontSize: '18px', color: LIGHT_GREEN }} />,
                status: t('liveStressDetection.readyToStart'),
                button: (
                    <Button
                        className={BUTTON_LIGHT_GREEN_CLASS}
                        icon={<CaretRightOutlined />}
                        // onClick={() => handlerStartButtonClick()}
                        disabled
                    >
                        {t('button.notStartedYet')}
                    </Button>
                )
            };
        }

        if (serviceBoat.hasEngineConfig) {
            return {
                borderColor: 'border-orange-500',
                icon: <InfoCircleFilled style={{ fontSize: '18px', color: ORANGE }} />,
                status: t('liveStressDetection.noEngineBaseUrl')
            };
        }

        return {
            borderColor: 'border-gray-500',
            icon: <CloseCircleFilled style={{ fontSize: '18px', color: GRAY }} />,
            status: t('liveStressDetection.noEngineConfig')
        };
    };

    return (
        <Spin spinning={serviceBoat.loading || false}>
            <div
                className={`relative border border-solid rounded p-2 bg-white h-36 ${
                    getServiceBoatStyle().borderColor
                }`}
            >
                <div className="flex items-center">
                    {getServiceBoatStyle().icon}
                    <span className="font-bold ml-2 truncate">{serviceBoat.name}</span>
                </div>
                <p className="font-medium mt-2">{getServiceBoatStyle().status}</p>

                <div className="absolute bottom-2 left-2 right-2 flex justify-around">
                    {getServiceBoatStyle().button}
                </div>
            </div>
        </Spin>
    );
};

ServiceBoatStatus.propTypes = {
    serviceBoat: propTypes.object,
    onPenListOpen: propTypes.func
};

export default memo(ServiceBoatStatus);
