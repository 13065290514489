import { createSlice } from '@reduxjs/toolkit';
import { getStockCount } from 'redux/thunks';

const initialState = {
    data: {
        count: null,
        pensMissingTotalFishCount: []
    },
    loading: false
};

export const stockCountSlice = createSlice({
    name: 'stockCount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStockCount.pending, (state) => {
                state.loading = true;
            })
            .addCase(getStockCount.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getStockCount.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
