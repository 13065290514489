import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setSelectedComparisonSites } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    getPenComparison,
    getPensSelection,
    getSiteComparison,
    getSitesSelection
} from 'redux/thunks';

const FilterBar = () => {
    const dispatch = useAppDispatch();

    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const { siteIds, penIds } = useSelector((s) => s.filter.data.comparison);
    const { fromDate, toDate } = useSelector((s) => s.filter.data);

    const handleFilterSubmit = useCallback((query) => {
        dispatch(getSiteComparison(query));
        dispatch(getPenComparison(query));

        appInsights.trackEvent({ name: 'Comparison filter apply' });
    }, []);

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useEffect(() => {
        if (!sitesSelection.length || siteIds.length || penIds.length) return;

        const defaultSiteIds = sitesSelection.map((s) => s.id);

        dispatch(setSelectedComparisonSites(defaultSiteIds));

        dispatch(
            getSiteComparison({
                siteIds: defaultSiteIds,
                fromDate: fromDate,
                toDate: toDate
            })
        );
    }, [sitesSelection]);

    return <FDFilter hasComparison hasDateRange callback={handleFilterSubmit} />;
};

export default FilterBar;
