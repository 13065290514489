import { createSlice } from '@reduxjs/toolkit';
import { updateLocale } from 'redux/thunks';

const initialState = {
    data: localStorage.getItem('language') ?? 'nb'
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateLocale.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateLocale.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
            localStorage.setItem('language', state.data);
        });
        builder.addCase(updateLocale.rejected, (state) => {
            state.loading = false;
            state.data = initialState.data;
        });
    }
});
