import { createSlice } from '@reduxjs/toolkit';
import { addEventNote, getEventNotesByEventId, pushEventNote } from 'redux/thunks';

const initialState = {
    data: [],
    isWaitingEventNote: false, // status of waiting for new message from event note hub
    loading: false
};

export const eventNoteSlice = createSlice({
    name: 'eventNote',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEventNotesByEventId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEventNotesByEventId.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getEventNotesByEventId.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(addEventNote.pending, (state) => {
                state.isWaitingEventNote = true;
            })
            .addCase(addEventNote.fulfilled, (state) => {
                state.isWaitingEventNote = false;
            })
            .addCase(addEventNote.rejected, (state) => {
                state.isWaitingEventNote = false;
            })

            .addCase(pushEventNote.pending, (state) => {
                state.isWaitingEventNote = true;
            })
            .addCase(pushEventNote.fulfilled, (state, action) => {
                state.isWaitingEventNote = false;
                state.data = [...state.data, action.payload];
            })
            .addCase(pushEventNote.rejected, (state) => {
                state.isWaitingEventNote = false;
            });
    }
});
