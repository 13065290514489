import { Button, Col, Row } from 'antd';
import { BUTTON_BLUE_CLASS, FISH_NAMES } from 'common/constants';
import { FDDate, FDMultipleSelection, FDRangeDate } from 'components';
import { useQueryFromFilters } from 'hooks/useQueryFromFilters';
import { isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import {
    setDailyDate,
    setFishNames,
    setFromDate,
    setSelectedComparisonPens,
    setSelectedComparisonSites,
    setSelectedFishGroups,
    setSelectedPens,
    setSelectedServiceBoats,
    setSelectedSites,
    setToDate
} from 'redux/slices/filterSlice';
import {
    setComparisonPensSelection,
    setFishGroupIdsSelection,
    setPensSelection
} from 'redux/slices/selection/selectionSlice';
import { useAppDispatch } from 'redux/store';

const FDFilter = memo((props) => {
    const {
        hasComparison,
        hasSite,
        hasPen,
        hasFishGroupIds,
        hasServiceBoat,
        hasDateRange,
        hasDate,
        hasFishName,
        callback
    } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        data: fishGroupIds,
        selection: fishGroupIdsSelection,
        loading: fishGroupIdsLoading
    } = useSelector((s) => s.selection.fishGroupIds, shallowEqual);

    const {
        data: pens,
        selection: pensSelection,
        comparisonSelection: comparisonPensSelection,
        loading: pensLoading
    } = useSelector((s) => s.selection.pens, shallowEqual);

    const { data: sites, loading: sitesLoading } = useSelector(
        (s) => s.selection.sites,
        shallowEqual
    );

    const { data: serviceBoats, loading: serviceBoatsLoading } = useSelector(
        (s) => s.selection.serviceBoats,
        shallowEqual
    );

    const {
        comparison,
        siteIds,
        penIds,
        fishGroupIds: selectedFishGroupIds,
        fishNames,
        serviceBoatIds,
        fromDate,
        toDate,
        daily
    } = useSelector((s) => s.filter.data, shallowEqual);

    const generatedQuery = useQueryFromFilters(omit(props, ['callback']));

    const handleChange = (action, value, extra) => {
        dispatch(action(value));
        if (extra) extra(value);
    };

    const getNewPensSelections = (siteIds) =>
        isEmpty(siteIds) ? pens : pens.filter((pen) => siteIds.includes(pen.siteId));

    useEffect(() => {
        callback(generatedQuery);
    }, [callback]);

    useEffect(() => {
        if (hasComparison) {
            const newPensSelections = getNewPensSelections(comparison.siteIds);

            const selectedPens = comparison.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );

            handleChange(setComparisonPensSelection, newPensSelections);

            handleChange(setSelectedComparisonPens, selectedPens);
        }

        if (hasPen) {
            const newPensSelections = getNewPensSelections(siteIds);
            const selectedPens = penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );
            handleChange(setPensSelection, newPensSelections);
            handleChange(setSelectedPens, selectedPens);
        }

        if (hasFishGroupIds) {
            const newFishGroupsSelections = isEmpty(siteIds)
                ? fishGroupIds
                : fishGroupIds.filter((fishGroup) => siteIds.includes(fishGroup.siteId));
            const selectedFishGroups = selectedFishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
            handleChange(setFishGroupIdsSelection, newFishGroupsSelections);
            handleChange(setSelectedFishGroups, selectedFishGroups);
        }
    }, [sites, comparison.siteIds, siteIds]);

    useEffect(() => {
        if (hasFishGroupIds) {
            const newFishGroupsSelections = isEmpty(penIds)
                ? fishGroupIds
                : fishGroupIds.filter((fishGroup) => penIds.includes(fishGroup.siteId));
            const selectedFishGroups = selectedFishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
            handleChange(setFishGroupIdsSelection, newFishGroupsSelections);
            handleChange(setSelectedFishGroups, selectedFishGroups);
        }
    }, [pens, penIds]);

    const renderFilter = (condition, title, Component, props) =>
        condition && (
            <Col xs={24} sm={12} xl={8} xxl={5}>
                <div className="font-semibold">{t(title)}</div>
                <Component {...props} />
            </Col>
        );

    return (
        <div className="filter-bar" data-testid="filter-bar">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>

            <Row className="md:text-base filter">
                {renderFilter(hasComparison, 'general.filterBar.site', FDMultipleSelection, {
                    placeholder: t('general.filterBar.sitesPlaceholder'),
                    listSelectItem: sites,
                    onChange: (siteIds) => handleChange(setSelectedComparisonSites, siteIds),
                    value: comparison.siteIds,
                    loading: sitesLoading
                })}
                {renderFilter(hasComparison, 'general.filterBar.pen', FDMultipleSelection, {
                    placeholder: t('general.filterBar.pensPlaceholder'),
                    listSelectItem: comparisonPensSelection,
                    onChange: (penIds) => handleChange(setSelectedComparisonPens, penIds),
                    value: comparison.penIds,
                    loading: pensLoading
                })}

                {renderFilter(hasSite, 'general.filterBar.site', FDMultipleSelection, {
                    placeholder: t('general.filterBar.sitesPlaceholder'),
                    listSelectItem: sites,
                    onChange: (siteIds) => handleChange(setSelectedSites, siteIds),
                    value: siteIds,
                    loading: sitesLoading
                })}
                {renderFilter(hasPen, 'general.filterBar.pen', FDMultipleSelection, {
                    placeholder: t('general.filterBar.pensPlaceholder'),
                    listSelectItem: pensSelection,
                    onChange: (penIds) => handleChange(setSelectedPens, penIds),
                    value: penIds,
                    loading: pensLoading
                })}
                {renderFilter(hasFishName, 'general.filterBar.fish', FDMultipleSelection, {
                    placeholder: t('general.filterBar.fishesPlaceholder'),
                    listSelectItem: FISH_NAMES.map((fish) => ({
                        ...fish,
                        text: t(fish.text)
                    })),
                    onChange: (fishes) => handleChange(setFishNames, fishes),
                    value: fishNames
                })}
                {renderFilter(
                    hasFishGroupIds,
                    'general.filterBar.fishGroups',
                    FDMultipleSelection,
                    {
                        placeholder: t('general.filterBar.fishGroupsPlaceholder'),
                        listSelectItem: fishGroupIdsSelection.map((fishGroup) => ({
                            ...fishGroup,
                            text: `${fishGroup.text} - ${fishGroup.penNumber} - ${fishGroup.siteName}`
                        })),
                        onChange: (fishGroupIds) =>
                            handleChange(setSelectedFishGroups, fishGroupIds),
                        value: selectedFishGroupIds,
                        loading: fishGroupIdsLoading
                    }
                )}
                {renderFilter(hasServiceBoat, 'general.filterBar.boat', FDMultipleSelection, {
                    placeholder: t('general.filterBar.boatsPlaceholder'),
                    listSelectItem: serviceBoats,
                    onChange: (serviceBoatIds) =>
                        handleChange(setSelectedServiceBoats, serviceBoatIds),
                    value: serviceBoatIds,
                    loading: serviceBoatsLoading
                })}
                {renderFilter(hasDateRange, 'general.filterBar.rangeDate', FDRangeDate, {
                    placeholder: [
                        t('general.filterBar.startDatePlaceholder'),
                        t('general.filterBar.endDatePlaceholder')
                    ],
                    onChange: (fromDate, toDate) => {
                        handleChange(setFromDate, fromDate);
                        handleChange(setToDate, toDate);
                    },
                    value: [fromDate, toDate]
                })}
                {renderFilter(hasDate, 'general.filterBar.date', FDDate, {
                    placeholder: t('general.filterBar.datePlaceholder'),
                    onChange: (date) => handleChange(setDailyDate, date),
                    value: daily.fromDate
                })}

                <Col xs={24} sm={12} lg={8} xxl={3}>
                    <Button
                        className={`${BUTTON_BLUE_CLASS} mt-6`}
                        onClick={() => {
                            callback(generatedQuery);
                        }}
                    >
                        {t('general.filterBar.apply')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
});

FDFilter.propTypes = {
    hasComparison: PropTypes.bool,
    hasSite: PropTypes.bool,
    hasPen: PropTypes.bool,
    hasFishGroupIds: PropTypes.bool,
    hasServiceBoat: PropTypes.bool,
    hasDateRange: PropTypes.bool,
    hasDate: PropTypes.bool,
    hasTimePeriod: PropTypes.bool,
    hasDailyDate: PropTypes.bool,
    hasFishName: PropTypes.bool,
    callback: PropTypes.func.isRequired
};

FDFilter.defaultProps = {
    hasComparison: false,
    hasSite: false,
    hasPen: false,
    hasFishGroupIds: false,
    hasServiceBoat: false,
    hasDateRange: false,
    hasDate: false,
    hasFishName: false,
    callback: () => {}
};

export default FDFilter;
