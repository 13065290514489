import { ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useAppAbility from 'casl/can';
import { SITE_MANAGER } from 'common/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import DeadFishCounting from './DeadFishCounting';
import './LiveFishEvent.scss';
import StressDetection from './StressDetection';
import { withPageViewTracking } from 'hoc';

const LiveFishEvent = () => {
    const { can } = useAppAbility();

    const { showLiveDeadFishCounting, showLiveStressDetection } = useFlags();

    const [isScrollToTopButtonVisible, setIsScrollToTopButtonVisible] = useState(false);

    const toggleVisibility = () => setIsScrollToTopButtonVisible(window.scrollY > 0);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className="live-fish-event">
            {showLiveDeadFishCounting && <DeadFishCounting />}

            {can('manage', SITE_MANAGER, ['all']) && showLiveStressDetection && <StressDetection />}

            <div className="fixed bottom-5 right-5">
                <Button
                    onClick={scrollToTop}
                    shape="circle"
                    icon={<ArrowUpOutlined />}
                    size="large"
                    className="shadow-sm"
                    hidden={!isScrollToTopButtonVisible}
                />
            </div>
        </div>
    );
};

export default withPageViewTracking(LiveFishEvent, 'Live Fish Event');
