import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { BLUE, LIGHT_GRAY } from 'common/constants';
import { alertErrorMessage, formatTime } from 'common/utils';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { triggerEngineStop } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import Swal from 'sweetalert2';
import ConfirmDialog from './ConfirmDialog';
import StreamingResult from './StreamingResult';
import StreamingVideo from './StreamingVideo';
import { appInsights } from 'AppInsights';

const StreamingCounting = ({
    timer = 0,
    selectedSite = {},
    selectedPen = {},
    onResetTimer = () => {},
    setSelectedPen = () => {}
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const eventSourceRef = useRef();

    const { loading } = useSelector((s) => s.engine);

    const stopCounting = async (pen) => {
        try {
            await dispatch(
                triggerEngineStop({
                    penNumber: pen.penNumber,
                    siteName: selectedSite.text,
                    localityNumber: selectedSite.localityNumber
                })
            ).unwrap();

            setSelectedPen(null);
            onResetTimer();
            NOTIFICATION_SERVICE.pushNotification({
                title: t('countingPage.notification.stoppedCounting'),
                duration: 10
            });

            appInsights.trackEvent({
                name: 'Stop dead fish counting successfully',
                properties: {
                    penNumber: pen.penNumber,
                    siteName: selectedSite.text,
                    localityNumber: selectedSite.localityNumber
                }
            });
        } catch (error) {
            alertErrorMessage(error);

            appInsights.trackEvent({
                name: 'Stop dead fish counting failed',
                properties: {
                    penNumber: pen.penNumber,
                    siteName: selectedSite.text,
                    localityNumber: selectedSite.localityNumber
                }
            });
        }
    };

    const handleCountingStop = (pen) => {
        Swal.fire({
            title: t('countingPage.streamingCounting.dialogStop.confirmMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('button.yes'),
            cancelButtonText: t('button.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (eventSourceRef?.current) {
                    // stop getting streaming data from engine
                    eventSourceRef.current.close();
                }
                stopCounting(pen);
            }
        });
    };

    return (
        <div
            className="stop-counting flex flex-col justify-center items-center"
            data-testid="streaming-counting"
        >
            <div>
                <p className="text-xl font-semibold text-center my-2">
                    {`${t('countingPage.streamingCounting.nowCountingForPen')} ${
                        selectedPen.penNumber
                    } ${t('countingPage.streamingCounting.ofSite')} ${selectedPen.siteName}`}
                </p>
                <p className="text-2xl font-semibold text-center my-2">{formatTime(timer)}</p>
            </div>

            <StreamingVideo />

            <Divider />

            <StreamingResult
                siteName={selectedPen.siteName}
                penNumber={selectedPen.penNumber}
                eventSourceRef={eventSourceRef}
                onCountingStop={() => stopCounting(selectedPen)}
            />

            <Button
                type="button"
                onClick={() => handleCountingStop(selectedPen)}
                icon={<CloseCircleOutlined className="text-xl" />}
                loading={loading}
                className="bg-sky-700 focus-within:bg-sky-700 text-white hover:bg-sky-800 hover:text-white focus-within:text-white text-base font-semibold 
                        h-10  mt-4 rounded border border-sky-800 flex justify-center items-center stop-counting-btn"
            >
                <span>{t('countingPage.streamingCounting.stopAndSaveTheCount')}</span>
            </Button>

            <ConfirmDialog message={'dialogBox.streamingConfirmMessage'} />
        </div>
    );
};

StreamingCounting.propTypes = {
    timer: PropTypes.number,
    selectedSite: PropTypes.object,
    selectedPen: PropTypes.object,
    onResetTimer: PropTypes.func,
    setSelectedPen: PropTypes.func
};

export default StreamingCounting;
