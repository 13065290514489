import { createSlice } from '@reduxjs/toolkit';
import {
    addFishGroup,
    deleteFishGroup,
    divideFishGroup,
    getFishGroups,
    moveFishGroup,
    releaseFishGroup
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const fishGroupSlice = createSlice({
    name: 'fishGroup',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFishGroups.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFishGroups.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getFishGroups.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(addFishGroup.pending, (state) => {
                state.loading = true;
            })
            .addCase(addFishGroup.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(addFishGroup.rejected, (state) => {
                state.loading = false;
            })

            .addCase(divideFishGroup.pending, (state) => {
                state.loading = true;
            })
            .addCase(divideFishGroup.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(divideFishGroup.rejected, (state) => {
                state.loading = false;
            })

            .addCase(moveFishGroup.pending, (state) => {
                state.loading = true;
            })
            .addCase(moveFishGroup.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(moveFishGroup.rejected, (state) => {
                state.loading = false;
            })

            .addCase(releaseFishGroup.pending, (state) => {
                state.loading = true;
            })
            .addCase(releaseFishGroup.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(releaseFishGroup.rejected, (state) => {
                state.loading = false;
            })

            .addCase(deleteFishGroup.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteFishGroup.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteFishGroup.rejected, (state) => {
                state.loading = false;
            });
    }
});
