import { createSlice } from '@reduxjs/toolkit';
import { getStressAnalysisForCorrelation, getStressAnalysisForRegression } from 'redux/thunks';
import { getStressAnalysisCorrelationTypes } from 'redux/thunks';

const initialState = {
    correlationType: { data: [], loading: false },
    regression: { data: [], loading: false },
    correlation: { data: {}, loading: false }
};

export const correlationAnalysisSlice = createSlice({
    name: 'correlationAnalysis',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStressAnalysisCorrelationTypes.pending, (state) => {
                state.correlationType.loading = true;
            })
            .addCase(getStressAnalysisCorrelationTypes.fulfilled, (state, action) => {
                state.correlationType.loading = false;
                state.correlationType.data = action.payload;
            })
            .addCase(getStressAnalysisCorrelationTypes.rejected, (state) => {
                state.correlationType.loading = false;
                state.correlationType.data = initialState.correlationType.data;
            })

            .addCase(getStressAnalysisForRegression.pending, (state) => {
                state.regression.loading = true;
            })
            .addCase(getStressAnalysisForRegression.fulfilled, (state, action) => {
                state.regression.loading = false;
                state.regression.data = action.payload;
            })
            .addCase(getStressAnalysisForRegression.rejected, (state) => {
                state.regression.loading = false;
                state.regression.data = initialState.regression.data;
            })

            .addCase(getStressAnalysisForCorrelation.pending, (state) => {
                state.correlation.loading = true;
            })
            .addCase(getStressAnalysisForCorrelation.fulfilled, (state, action) => {
                state.correlation.loading = false;
                state.correlation.data = action.payload;
            })
            .addCase(getStressAnalysisForCorrelation.rejected, (state) => {
                state.correlation.loading = false;
                state.correlation.data = initialState.correlation.data;
            });
    }
});
