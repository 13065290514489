import { createSlice } from '@reduxjs/toolkit';
import {
    addOtherDeadFishCount,
    deleteOtherDeadFishCount,
    getOtherDeadFishCounts,
    updateOtherDeadFishCount
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const otherDeadFishCountSlice = createSlice({
    name: 'otherDeadFishCount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOtherDeadFishCounts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOtherDeadFishCounts.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        });
        builder.addCase(getOtherDeadFishCounts.rejected, (state) => {
            state.loading = false;
            state.data = initialState.data;
        });

        builder.addCase(addOtherDeadFishCount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addOtherDeadFishCount.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(addOtherDeadFishCount.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(updateOtherDeadFishCount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateOtherDeadFishCount.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(updateOtherDeadFishCount.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(deleteOtherDeadFishCount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteOtherDeadFishCount.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(deleteOtherDeadFishCount.rejected, (state) => {
            state.loading = false;
        });
    }
});
