import { createSlice } from '@reduxjs/toolkit';
import { getReportTable } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const reportTableSlice = createSlice({
    name: 'reportTable',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReportTable.pending, (state) => {
                state.loading = true;
            })
            .addCase(getReportTable.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getReportTable.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
