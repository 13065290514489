import { createSlice } from '@reduxjs/toolkit';
import { getScoringEventDetail, getScoringEventList } from 'redux/thunks';

const initialState = {
    list: {
        data: [],
        loading: false
    },
    detail: {
        data: [],
        loading: false
    }
};

export const scoringEventSlice = createSlice({
    name: 'scoringEventSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getScoringEventList.pending, (state) => {
            state.list.loading = true;
        });
        builder.addCase(getScoringEventList.fulfilled, (state, action) => {
            state.list.loading = false;
            state.list.data =
                action.payload?.map((scoring) => ({
                    id: scoring.ids.toString(),
                    ...scoring
                })) || initialState.list.data;
        });
        builder.addCase(getScoringEventList.rejected, (state) => {
            state.list.loading = false;
            state.list.data = initialState.list.data;
        });

        builder.addCase(getScoringEventDetail.pending, (state) => {
            state.detail.loading = true;
        });
        builder.addCase(getScoringEventDetail.fulfilled, (state, action) => {
            state.detail.loading = false;
            state.detail.data = action.payload || initialState.detail.data;
        });
        builder.addCase(getScoringEventDetail.rejected, (state) => {
            state.detail.loading = false;
            state.detail.data = initialState.detail.data;
        });
    }
});
