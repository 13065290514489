import { createSlice } from '@reduxjs/toolkit';
import { getCompanyConfigs, updateCompanyConfig } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const companyConfigSlice = createSlice({
    name: 'companyConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyConfigs.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyConfigs.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getCompanyConfigs.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(updateCompanyConfig.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCompanyConfig.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateCompanyConfig.rejected, (state) => {
                state.loading = false;
            });
    }
});
