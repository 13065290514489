import { appInsights } from 'AppInsights';
import { Spin } from 'antd';
import { BLUE, STRESS_EVENT_PAGE } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDBubble } from 'components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    setSelectedPens,
    setSelectedSites,
    setSelectedStressEventId
} from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getEventChart } from 'redux/thunks';

const EventChart = () => {
    const EVENT_SIZE = 20;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { siteId, penId } = useParams();

    const { timePeriod } = useSelector((s) => s.filter.data);
    const { data: eventData, loading } = useSelector((s) => s.eventChart);

    useEffect(() => {
        dispatch(
            getEventChart({
                siteIds: [siteId],
                penIds: [penId],
                fromDate: timePeriod.fromDate
            })
        );
    }, [siteId, penId, timePeriod.fromDate]);

    const handleEventClick = async (eventId) => {
        dispatch(setSelectedSites(siteId ? [siteId] : []));
        dispatch(setSelectedPens(penId ? [penId] : []));
        dispatch(setSelectedStressEventId(eventId));

        navigate(STRESS_EVENT_PAGE, { state: { navigatedEventId: eventId } });

        appInsights.trackEvent({ name: 'Event click', properties: { eventId } });
    };

    return (
        <Spin spinning={loading}>
            <div className="h-[300px]">
                <FDBubble
                    maintainAspectRatio={false}
                    data={{
                        labels: eventData.map((item) => formatDateTime(item.date)),
                        datasets: [
                            {
                                data: eventData.flatMap((item) => {
                                    let y = 1;
                                    return item.events?.map((event) => ({
                                        id: event.eventId,
                                        x: formatDateTime(item.date),
                                        y: y++,
                                        r: EVENT_SIZE,
                                        type: event.eventType?.charAt(0)
                                    }));
                                }),
                                backgroundColor: BLUE
                            }
                        ]
                    }}
                    onItemClick={handleEventClick}
                />
            </div>
        </Spin>
    );
};

export default EventChart;
