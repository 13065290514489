import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/store';
import {
    getEventList,
    getPensSelection,
    getServiceBoatsSelection,
    getSitesSelection
} from 'redux/thunks';
import TableEventList from './TableEventList/TableEventList';

const StressEventList = () => {
    const dispatch = useAppDispatch();

    const handleFilterSubmit = (query) => {
        dispatch(getEventList(query));

        appInsights.trackEvent({ name: 'Stress event list filter apply' });
    };

    useEffect(() => {
        dispatch(getServiceBoatsSelection());
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasServiceBoat hasDateRange callback={handleFilterSubmit} />
            <TableEventList />
        </>
    );
};

export default withPageViewTracking(StressEventList, 'Stress event list');
