import { createSlice } from '@reduxjs/toolkit';
import { getPenComparison } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const penComparisonSlice = createSlice({
    name: 'penComparison',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPenComparison.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPenComparison.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getPenComparison.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            });
    }
});
