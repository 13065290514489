import { createSlice } from '@reduxjs/toolkit';
import {
    DEFAULT_CHART_TYPE,
    DEFAULT_GRAPH,
    DEFAULT_REPORT,
    PREVIOUS_DAYS_DEFAULT,
    TIME_PERIOD_DEFAULT,
    WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH
} from 'common/constants';
import { getPreviousDate } from 'common/utils';
import moment from 'moment';
import { updateFilters } from 'redux/thunks';

const initialState = {
    data: {
        daily: {
            fromDate: moment().startOf('day'),
            toDate: moment().endOf('day')
        },
        fromDate: getPreviousDate(PREVIOUS_DAYS_DEFAULT),
        toDate: moment().endOf('day'),
        timePeriod: {
            value: TIME_PERIOD_DEFAULT.id,
            fromDate: getPreviousDate(PREVIOUS_DAYS_DEFAULT)
        },
        comparison: {
            siteIds: [],
            penIds: []
        },
        graphType: DEFAULT_GRAPH,
        chartType: DEFAULT_CHART_TYPE,
        reportType: DEFAULT_REPORT,
        woundTypeReportType: WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH,
        fromDead: null,
        toDead: null,
        fishNames: [],
        siteIds: [],
        penIds: [],
        fishGroupIds: [],
        serviceBoatIds: [],
        selectedStressEventId: null,
        selectedScoringEventId: null,
        selectedMortalityId: null,
        selectedProcessId: null,
        selectedFishGroupId: null,
        stressLevelStatus: null
    },
    loading: false
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setSelectedServiceBoats(state, action) {
            state.data.serviceBoatIds = action.payload;
        },
        setSelectedSites(state, action) {
            state.data.siteIds = action.payload;
        },
        setSelectedPens(state, action) {
            state.data.penIds = action.payload;
        },
        setSelectedFishGroups(state, action) {
            state.data.fishGroupIds = action.payload;
        },
        setFromDate(state, action) {
            state.data.fromDate = action.payload;
        },
        setToDate(state, action) {
            state.data.toDate = action.payload;
        },
        setTimePeriod(state, action) {
            state.data.timePeriod = action.payload;
        },
        setDailyDate(state, action) {
            state.data.daily.fromDate = action.payload.clone().startOf('day');
            state.data.daily.toDate = action.payload.clone().endOf('day');
        },
        setFishNames(state, action) {
            state.data.fishNames = action.payload;
        },
        setSelectedComparisonSites(state, action) {
            state.data.comparison.siteIds = action.payload;
        },
        setSelectedComparisonPens(state, action) {
            state.data.comparison.penIds = action.payload;
        },
        setSelectedStressEventId(state, action) {
            state.data.selectedStressEventId = action.payload;
        },
        setSelectedMortalityId(state, action) {
            state.data.selectedMortalityId = action.payload;
        },
        setGraphType(state, action) {
            state.data.graphType = action.payload;
        },
        setChartType(state, action) {
            state.data.chartType = action.payload;
        },
        setSelectedFishGroupId(state, action) {
            state.data.selectedFishGroupId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateFilters.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateFilters.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload ? { ...state.data, ...action.payload } : initialState.data;
        });
        builder.addCase(updateFilters.rejected, (state) => {
            state.loading = false;
            state.data = initialState.data;
        });
    }
});

export const {
    setSelectedServiceBoats,
    setSelectedSites,
    setSelectedPens,
    setSelectedFishGroups,
    setFromDate,
    setToDate,
    setTimePeriod,
    setDailyDate,
    setFishNames,
    setSelectedComparisonSites,
    setSelectedComparisonPens,
    setSelectedStressEventId,
    setSelectedMortalityId,
    setGraphType,
    setChartType,
    setSelectedFishGroupId
} = filterSlice.actions;
