import { formatDateTime } from 'common/utils';
import moment from 'moment';
import { DATE_TIME_FORMAT } from './format-type';

export const TABLE_COLUMNS = {
    SITE: {
        title: 'general.table.site',
        dataIndex: 'site',
        sorter: {
            compare: (a, b) => a.site.localeCompare(b.site),
            multiple: 1
        },
        className: 'table-report-site',
        isDefault: true
    },
    PEN: {
        title: 'general.table.pen',
        dataIndex: 'pen',
        sorter: {
            compare: (a, b) => {
                const lengthDifference = a.pen.length - b.pen.length;
                if (lengthDifference !== 0) {
                    return lengthDifference;
                }
                return a.pen.localeCompare(b.pen);
            },
            multiple: 2
        },
        className: 'table-report-pen',
        isDefault: true
    },
    START_TIME: {
        title: 'general.table.startTime',
        dataIndex: 'startTime',
        sorter: {
            compare: (a, b) => moment(a.startTime) - moment(b.startTime),
            multiple: 3
        },
        render: (startTime) => formatDateTime(startTime, DATE_TIME_FORMAT),
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-start-time',
        isDefault: true
    },
    STOP_TIME: {
        title: 'general.table.stopTime',
        dataIndex: 'stopTime',
        className: 'table-report-stop-time',
        render: (stopTime) => formatDateTime(stopTime, DATE_TIME_FORMAT),
        sorter: {
            compare: (a, b) => moment(a.stopTime) - moment(b.stopTime),
            multiple: 4
        },
        sortDirections: ['ascend', 'descend'],
        isDefault: true
    },
    DATE: {
        title: 'general.table.date',
        dataIndex: 'stopTime',
        className: 'table-report-date',
        render: (stopTime) => formatDateTime(stopTime),
        sorter: {
            compare: (a, b) => moment(a.stopTime) - moment(b.stopTime),
            multiple: 5
        },
        sortDirections: ['ascend', 'descend'],
        isDefault: true
    },
    LAKS: {
        title: 'general.fishMortality.laks',
        dataIndex: 'laks',
        sorter: {
            compare: (a, b) => a.laks - b.laks,
            multiple: 6
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-laks'
    },
    ØRRET: {
        title: 'general.fishMortality.ørret',
        dataIndex: 'ørret',
        sorter: {
            compare: (a, b) => a.ørret - b.ørret,
            multiple: 7
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-ørret'
    },
    BERGGYLT: {
        title: 'general.fishMortality.berggylt',
        dataIndex: 'berggylt',
        sorter: {
            compare: (a, b) => a.berggylt - b.berggylt,
            multiple: 8
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-berggylt'
    },
    ROGNKJEKS: {
        title: 'general.fishMortality.rognkjeks',
        dataIndex: 'rognkjeks',
        sorter: {
            compare: (a, b) => a.rognkjeks - b.rognkjeks,
            multiple: 9
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-rognkjeks'
    },
    BOAT: {
        title: 'general.table.boat',
        dataIndex: 'boat',
        sorter: {
            compare: (a, b) => a.boat.localeCompare(b.boat),
            multiple: 10
        }
    },
    BOAT_COMPANY: {
        title: 'general.table.boatCompany',
        dataIndex: 'ownerOrganizationName',
        sorter: {
            compare: (a, b) => a.ownerOrganizationName.localeCompare(b.ownerOrganizationName),
            multiple: 11
        }
    },
    EVENT_TYPE: {
        title: 'general.table.eventType',
        dataIndex: 'eventType',
        sorter: {
            compare: (a, b) => a.eventType.localeCompare(b.eventType),
            multiple: 12
        }
    }
};
