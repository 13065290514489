import { Table } from 'antd';
import { RECORD_PER_PAGE } from 'common/constants';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FDTable.scss';

const FDTable = ({
    loading = false,
    headers = [],
    data = [],
    totalRecords = 0,
    page = 1,
    onPaginationChange = () => {},
    ...props
}) => {
    const { t } = useTranslation();

    const [paginationState, setPagination] = useState({ current: page });
    const onChange = (current, pageSize) => {
        setPagination({ current, pageSize });
        onPaginationChange(current, pageSize);
    };

    const pagination = useMemo(() => {
        if (typeof props.pagination === 'boolean') return props.pagination;
        return {
            defaultPageSize: RECORD_PER_PAGE,
            defaultCurrent: 1,
            showSizeChanger: true,
            total: totalRecords || data.length,
            ...paginationState,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            onChange,
            onShowSizeChange: onChange
        };
    }, [props.pagination, totalRecords, paginationState, onChange]);

    const emptyData = useMemo(() => {
        if (!loading && isEmpty(data))
            return {
                emptyText: (
                    <span>
                        <img alt="ava" src={require('assets/Empty_Box.png')} />
                        <p style={{ marginRight: '2px' }}>No Data</p>
                    </span>
                )
            };

        return null;
    }, [loading, data]);

    return (
        <Table
            size="middle"
            loading={loading}
            loadingIndicator={<div>loading...</div>}
            columns={headers
                .filter((col) => !col.hidden)
                .map((col) => ({ ...col, title: t(col.title) }))}
            locale={emptyData}
            dataSource={data}
            bordered
            pagination={pagination}
            {...props}
        />
    );
};

FDTable.propTypes = {
    loading: PropTypes.bool,
    headers: PropTypes.array,
    data: PropTypes.array,
    totalRecords: PropTypes.number,
    page: PropTypes.number,
    onPaginationChange: PropTypes.func
};

export default FDTable;
